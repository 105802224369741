import { useEffect, useState } from "react";
import { useGetProgressQuery, useUploadProgressMutation } from "../api/progressApi";

// Custom hook to handle fetching and posting progress
export const useProgress = (projectId, imageId, isOpen) => {
    // Only query the progress when the dialog is open
    const { data: progressData, error: getError, isLoading: isGettingProgress } = useGetProgressQuery( { projectId, imageId },{ skip: !isOpen } );

    // Mutation to upload progress
    const [uploadProgress, { error: uploadError, isLoading: isUploadingProgress }] = useUploadProgressMutation();

    // State for managing progress input
    const [progress, setProgress] = useState([]);

    // Update local progress state when fetched progress data is available
    useEffect(() => {
        if (progressData && isOpen) {
            // Transform the fetched progressData into an array of objects with id, name, and isAnnotated
            const formattedProgress = Object.entries(progressData).map(([labelId, { name, isAnnotated }]) => ({
                labelId,
                name,
                isAnnotated,
            }));
            setProgress(formattedProgress);
        }
    }, [progressData, isOpen]);

    // Function to handle progress upload
    const submitProgress = async (newProgress) => {
        try {
            // Format the newProgress back into the original structure expected by the backend
            const formattedProgress = newProgress.reduce((acc, { labelId, isAnnotated }) => {
                acc[labelId] = isAnnotated;
                return acc;
            }, {});

            const progressPayload = {
                projectId,
                imageId,
                progress: JSON.stringify(formattedProgress), // Convert to JSON string
            };
            const result = await uploadProgress(progressPayload);
            return result;
        } catch (error) {
            console.error("Error uploading progress:", error);
        }
    };

    return { progress, setProgress, submitProgress, isGettingProgress, isUploadingProgress, getError, uploadError };
};
