import { useState, useEffect } from 'react';
import { useGetJobsQuery } from '../api/jobApi';

const useWorkerJobs = (workerId) => {
    const [allJobs, setAllJobs] = useState([]);
    const [totalJobs, setTotalJobs] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Only trigger the query if projectId and workerId are not null
    const skipQuery = !workerId;
    const { data, error: fetchError, isLoading, refetch } = useGetJobsQuery(
        { workerId },
        { skip: skipQuery } // Use the skip option to prevent execution
    );

    useEffect(() => {
        if (skipQuery) {
            setLoading(false);
            setAllJobs([]);
            setTotalJobs(0);
            return;
        }

        if (isLoading) {
            setLoading(true);
            setError(null);
        } else if (fetchError) {
            setLoading(false);
            setError(fetchError);
        } else if (data) {
            const { items, total } = data;
            setAllJobs(items);
            setTotalJobs(total);
            setLoading(false);
        }
    }, [data, fetchError, isLoading, skipQuery]);

    // Function to load all jobs
    const fetchAllJobs = async () => {
        let offset = 0;
        const allItems = [];

        // Fetch while more items exist
        while (offset < totalJobs) {
            const result = await refetch({ limit: 100, offset });
            if (result.error) {
                setError(result.error);
                break;
            }
            allItems.push(...result.data.items);
            offset += 100;
        }
        setAllJobs(allItems);
    };

    // Call fetchAllJobs when the totalJobs value updates
    useEffect(() => {
        if (totalJobs > 0) {
            fetchAllJobs();
        }
    }, [totalJobs]); // eslint-disable-line react-hooks/exhaustive-deps

    return { jobs: allJobs, totalJobs, loading, error };
};

export default useWorkerJobs;
