import { useState, useEffect } from 'react';
import { useGetJobContentQuery, useGetJobContentIdsQuery } from '../api/jobApi';  // Import both queries

const usePaginatedJobs = (jobId, refetchKey = null) => {
    // States for pagination and data
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [items, setItems] = useState([]);
    const [imageIdList, setImageIdList] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [loading, setLoading] = useState(false);

    // Query for paginated job content (for UI display)
    const { data, refetch: refetchJobContent } = useGetJobContentQuery({ jobId, limit, offset }, { skip: !jobId });

    // Query for all image IDs associated with the job (using the new endpoint)
    const { data: imageIdsData, refetch: refetchJobImageIds } = useGetJobContentIdsQuery({ jobId }, { skip: !jobId });

    // Function to handle page change
    const handlePageChange = (event, newPage) => {
        if (newPage === page) return;
        const newOffset = (newPage - 1) * limit;
        setPage(newPage);
        setOffset(newOffset);
        setItems([]); // Clear the items while new data is fetched
        setSelectedItemId(null);
        setLoading(true);
    };

    // Refetch both job content and image IDs on external refetch key or when jobId changes
    useEffect(() => {
        if (refetchKey || jobId) {
            setLoading(true);
            refetchJobContent();
            refetchJobImageIds(); // Refetch both the paginated content and image ID list
        }
    }, [refetchKey, jobId, limit, offset, refetchJobContent, refetchJobImageIds]);

    // Update the items and total when new paginated job content arrives
    useEffect(() => {
        if (data) {
            const { items: newItems, total } = data;
            setItems(newItems); // Replace existing items with new data
            setTotalItems(total);
            setLoading(false);
        }
    }, [data]);

    // Update the imageIdList when the image ID data arrives
    useEffect(() => {
        if (imageIdsData) {
            setImageIdList(imageIdsData.items); // Store the list of image IDs from the new endpoint
        }
    }, [imageIdsData]);

    return {
        items,
        totalItems,
        page,
        limit,
        offset,
        selectedItemId,
        setSelectedItemId,
        handlePageChange,
        loading,
        imageIdList,
    };
};

export default usePaginatedJobs;
