import React, { useState, useEffect } from 'react';
import { Box, Paper, List, ListItem, Typography, Pagination, Button, MenuItem, Select, FormControl, InputLabel, ListItemText } from '@mui/material';
import { useAppContext } from '../../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';
import useAllProjects from '../../../hooks/useAllProjects';
import AddJobDialog from '../dialogs/AddJobDialog';
import EditJobDialog from '../dialogs/EditJobDialog';

import { useUploadJobMutation, useDeleteJobMutation, useEditJobMutation, useLazyGetJobsQuery } from '../../../api/jobApi';

const renderItem = (job, index) => {
    return (
        <ListItemText
            primary={`${index}. ${job.name}`}
            secondary={`Status: ${job.status}`}
        />
    );
};

const JobExplorerList = ({ setProjectId, workerId, setSelectedJobId, changesToSave }) => {
    // Context
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);

    // Projects
    const { projects } = useAllProjects();
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);

    // Jobs
    const [getJobsMutation, { data: jobsData }] = useLazyGetJobsQuery();
    const [uploadJobMutation ] = useUploadJobMutation();
    const [deleteJobMutation ] = useDeleteJobMutation();
    const [editJobMutation ] = useEditJobMutation();
    const jobs = jobsData?.items || [];
    const totalJobs = jobsData?.total || 0;

    // Dialogs
    const [isAddJobDialogOpen, setIsAddJobDialogOpen] = useState(false);
    const [isEditJobDialogOpen, setIsEditJobDialogOpen] = useState(false);

    // Clear everything when workerId changes
    useEffect(() => {
        const handleWorkerChange = async () => {
            if (changesToSave) {
                const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
                if (!result) return;
            }

            // If workerId is null, clear everything
            if (!workerId || !selectedProjectId) {
                setSelectedItemId(null);
                return;
            } else {
                getJobsMutation({ projectId: selectedProjectId, workerId });
            }
        };
        handleWorkerChange(); // Call the async function
    }, [workerId, changesToSave, openConfirmationDialog]); // eslint-disable-line react-hooks/exhaustive-deps


    // Handle project selection change
    const handleProjectChange = (event) => {
        // Verify if workerId is not null otherwise inform the user
        if (!workerId) {
            openNotificationDialog('Worker Required', 'Please select a worker to view jobs.');
            return;
        }
        const projectId = event.target.value;
        setSelectedProjectId(projectId);
        setProjectId(projectId);
        setSelectedItemId(null);
        getJobsMutation({ projectId, workerId });
    };

    const handleItemClick = async (itemId) => {
        // If changesToSave is true, prompt user to save changes
        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }
        setSelectedItemId((prevSelected) => (prevSelected === itemId ? null : itemId));
    };

    useEffect(() => {
        setSelectedJobId(selectedItemId);
    }, [selectedItemId, setSelectedJobId]);


    const addItemCall = async () => {
        // Check if workerId and projectId are not null
        if (!workerId || !selectedProjectId) {
            openNotificationDialog('Worker and Project Required', 'Please select a worker and project to add a job.');
            return;
        }
        setIsAddJobDialogOpen(true);
    };

    const handleAddJobDialogClose = () => {
        setIsAddJobDialogOpen(false);
    };

    const handleCreateJob = async (newJob) => {

        try {
            const result = await uploadJobMutation(newJob);
            if (result.error) {
                await openNotificationDialog('Error', result.error.data.detail);
            } else {
                getJobsMutation({ projectId: selectedProjectId, workerId });
                setIsAddJobDialogOpen(false);
            }
        } catch (error) {
            await openNotificationDialog('Error', 'An error occurred while creating the job.');
        }
    };

    const handleEditJob = async (editedJob) => {
        try {
            const result = await editJobMutation({ jobId: selectedItemId, jobUpdateData: editedJob });
            if (result.error) {
                const errorMessage = result.error.data?.detail || 'An unknown error occurred';
                await openNotificationDialog('Error', errorMessage);
            } else {
                getJobsMutation({ projectId: selectedProjectId, workerId });
                setIsEditJobDialogOpen(false);
            }
        } catch (error) {
            const errorMessage = error.data?.detail || 'An error occurred while editing the job.';
            await openNotificationDialog('Error', errorMessage);
        }
    };

    const editItemCall = async () => {
        if (!selectedItemId) {
            openNotificationDialog('No Job Selected', 'Please select a job to edit.');
            return;
        }
        setIsEditJobDialogOpen(true);
    }

    const deleteItemCall = async () => {
        if (!selectedItemId) {
            openNotificationDialog('No Job Selected', 'Please select a job to edit.');
            return;
        }

        const confirmation = await openConfirmationDialog('Delete Job', 'Are you sure you want to delete this job?');
        if (!confirmation) return;

        try {
            const result = await deleteJobMutation(selectedItemId);
            if (result.error) {
                await openNotificationDialog('Error', result.error.data.detail);
            } else {
                getJobsMutation({ projectId: selectedProjectId, workerId });
                setSelectedItemId(null);
            }
        } catch (error) {
            await openNotificationDialog('Error', 'An error occurred while deleting the job.');
        }
    }

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}>

            {/* Typography */}
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', mb:2 }}>
                Jobs
            </Typography>

            {/* Project Selector */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="project-selector-label">Select Project</InputLabel>
                <Select
                    labelId="project-selector-label"
                    value={selectedProjectId? selectedProjectId : ''}
                    onChange={handleProjectChange}
                    label="Select Project"
                >
                    {projects?.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* List container with constrained height and scrollable overflow */}
            <Box sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 2, padding: 1, overflowY: 'auto', minHeight: 0 }}>
                <List>
                    {jobs.length > 0 && workerId ? (
                        jobs.map((item, index) => (
                            <ListItem
                                key={item.id}
                                selected={item.id === selectedItemId}
                                onClick={() => handleItemClick(item.id)}
                                sx={{
                                    cursor: 'pointer',
                                    backgroundColor: item.id === selectedItemId ? 'primary.light' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'primary.highlight',
                                        color: 'white',
                                    },
                                    transition: 'background-color 0.3s ease',
                                    borderRadius: '4px',
                                }}
                            >
                                {renderItem(item, index + 1)}
                            </ListItem>
                        ))
                    ) : (
                        <Typography sx={{ textAlign: 'center', padding: '16px' }}>No items to display</Typography>
                    )}
                </List>
            </Box>

            {/* Display total number of items */}
            <Typography sx={{ marginTop: '2px', marginBottom: 0, textAlign: 'right' }}>
                Total: {workerId ? totalJobs : 0}
            </Typography>

            {/* Control buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap:2 }}>
                <Button variant="contained" color="primary" sx={{ flexGrow: 1 }} onClick={addItemCall}>Add</Button>
                <Button variant="contained" color="primary" sx={{ flexGrow: 1 }} onClick={editItemCall}>Edit</Button>
                <Button variant="contained" color="error" sx={{ flexGrow: 1 }} onClick={deleteItemCall}>Delete</Button>
            </Box>

            {/* Add Job Dialog */}
            <AddJobDialog
                open={isAddJobDialogOpen}
                onClose={handleAddJobDialogClose}
                onCreate={handleCreateJob}
                creatorId={user.id}
                workerId={workerId}
                projectId={selectedProjectId}
            />

            {/* Edit Job Dialog */}
            <EditJobDialog
                open={isEditJobDialogOpen}
                onClose={() => setIsEditJobDialogOpen(false)}
                onEdit={handleEditJob}
                creatorId={user.id}
                workerId={workerId}
                projectId={selectedProjectId}
                initialData={jobs.find((job) => job.id === selectedItemId) || {}}
            />

        </Paper>
    );
};

export default JobExplorerList;
