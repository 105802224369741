import React, { useState, useEffect } from 'react';
import { Toolbar, Typography, IconButton, Avatar, Menu, MenuItem, Box, styled } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { drawerWidth } from './config';
import { logout } from '../../utils/authSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useAppContext } from '../../utils/ApplicationProvider';

// AppBar with conditional styling based on the open prop
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'})(({ theme, open }) => ({
    width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
    marginLeft: open ? `${drawerWidth}px` : 0,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

// Topbar component
const Topbar = ({ open, handleDrawerOpen }) => {
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tokenRemainingTime, setTokenRemainingTime] = useState(null);
    const [warningAcknowledged, setWarningAcknowledged] = useState(false);
    const dispatch = useDispatch();
    const userInitial = user?.email ? user.email[0].toUpperCase() : '';
    const { openNotificationDialog } = useAppContext();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const calculateTokenTime = () => {
        const currentTime = new Date().getTime();
        const tokenExpiryTime = user?.exp * 1000;
        const remainingTime = tokenExpiryTime - currentTime;
        return Math.max(0, remainingTime);
    };

    useEffect(() => {
        if (isAuthenticated) {
            const interval = setInterval(() => {
                const checkSessionExpiration = async () => {
                    const remainingTime = calculateTokenTime();
                    setTokenRemainingTime(remainingTime);

                    // Show warning only if the remaining time is less than 5 minutes and warning hasn't been acknowledged yet
                    if (remainingTime < 5 * 60 * 1000 && remainingTime > 0 && !warningAcknowledged) {
                        await openNotificationDialog('Session Expiring', 'Your session will expire soon. Please logout and login again to continue.');
                        setWarningAcknowledged(true);
                    }

                    // Automatically log out if the token has expired
                    if (remainingTime <= 0) {
                        dispatch(logout());
                    }
                };

                // Call the async function inside the interval
                checkSessionExpiration();
            }, 1000);

            // Cleanup the interval on component unmount
            return () => clearInterval(interval);
        }
    }, [isAuthenticated, logout, warningAcknowledged, openNotificationDialog]);

    // Format the remaining time in hours, minutes, and seconds
    const formatRemainingTime = () => {
        if (tokenRemainingTime === null) return '';
        if (tokenRemainingTime <= 0) return 'Session expired';

        const hours = Math.floor(tokenRemainingTime / 3600000);
        const minutes = Math.floor((tokenRemainingTime % 3600000) / 60000);
        const seconds = Math.floor((tokenRemainingTime % 60000) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    // JSX
    return (
        <AppBar position="fixed" open={open}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isAuthenticated && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}

                    <Typography variant="h6" noWrap component="div">
                        DashIA
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                        {formatRemainingTime()}
                    </Typography>

                    {isAuthenticated && user && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton color="inherit" onClick={handleMenuOpen}>
                                <Avatar>{userInitial}</Avatar>
                            </IconButton>

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    )}
                </Box>

            </Toolbar>
        </AppBar>
    );
};

export default Topbar;
