import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { Bar } from 'react-chartjs-2';
import { Box, Paper, Skeleton, Typography, ToggleButton } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useStats } from '../../../hooks/useStats';
import { getContrastColor } from '../../../utils/colorUtils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const FrequencyStats = ({ projectId }) => {
    const { annotationStats, isLoadingAnnotationStats } = useStats(projectId);
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({});
    const [visibleLabels, setVisibleLabels] = useState([]); // Labels to toggle visibility
    const chartRef = useRef(null); // Create a ref to access the chart instance

    useEffect(() => {
        if (annotationStats) {
            const labels = Object.keys(annotationStats).map(id => annotationStats[id].name);
            const colors = Object.keys(annotationStats).map(id => annotationStats[id].color);
            const data = Object.keys(annotationStats).map(id => annotationStats[id].count);

            setVisibleLabels(labels);

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Annotation Count',
                        data,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            });

            setChartOptions({
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
            });
        }
    }, [annotationStats]);

    const handleToggleLabelVisibility = (labelName) => {
        setVisibleLabels((prevVisibleLabels) =>
            prevVisibleLabels.includes(labelName)
                ? prevVisibleLabels.filter((name) => name !== labelName)
                : [...prevVisibleLabels, labelName]
        );
    };


    const filteredChartData = chartData
        ? {
              ...chartData,
              labels: chartData.labels.filter((label) => visibleLabels.includes(label)),
              datasets: chartData.datasets.map((dataset) => ({
                  ...dataset,
                  data: dataset.data.filter((_, index) => visibleLabels.includes(chartData.labels[index])),
                  backgroundColor: dataset.backgroundColor.filter((_, index) => visibleLabels.includes(chartData.labels[index])),
                  borderColor: dataset.borderColor.filter((_, index) => visibleLabels.includes(chartData.labels[index])),
              })),
          }
        : null;

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding:2}}>
            {/* Configuration options at the top */}

            {filteredChartData ? (
                <>
                {/* Chart Area center */}
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', marginBottom:2 }}>
                        Annotation Frequency
                </Typography>

                <Box sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 2, padding: 1, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Bar ref={chartRef} data={filteredChartData} options={chartOptions} />
                </Box>

                {/* Label Visibility Toggle */}
                <Box sx={{ marginTop: 2, padding: 1, backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
                    <Typography>Toggle Label Visibility</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {chartData &&
                            chartData.labels.map((label, index) => (
                                <ToggleButton
                                    key={label}
                                    value={label}
                                    selected={visibleLabels.includes(label)}
                                    onClick={() => handleToggleLabelVisibility(label)}
                                    sx={{
                                        height: 32,
                                        borderColor: chartData.datasets[0].borderColor[index],
                                        color: chartData.datasets[0].borderColor[index],
                                        backgroundColor: 'transparent',
                                        '&:hover': {
                                            backgroundColor: chartData.datasets[0].borderColor[index],
                                            color: getContrastColor(chartData.datasets[0].borderColor[index]),
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: chartData.datasets[0].borderColor[index],
                                            color: getContrastColor(chartData.datasets[0].borderColor[index]),
                                            '&:hover': {
                                                backgroundColor: chartData.datasets[0].borderColor[index],
                                            },
                                        },
                                        borderWidth: 2,
                                        textTransform: 'none',
                                    }}
                                >
                                    {label}
                                </ToggleButton>
                            ))}
                    </Box>
                </Box>
            </>
            ) : (
                <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} />
            )}
        </Paper>
    );
};

export default FrequencyStats;
