import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const ClientDrawer = () => {
    const location = useLocation();
    const theme = useTheme();

    return (
        <List>

            {/* Jobs Client */}
            <ListItem
                component={Link}
                to="/client/jobs"
                selected={location.pathname === '/client/jobs'}
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <EngineeringIcon />
                </ListItemIcon>
                <ListItemText primary="Jobs" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>

            {/* Logout */}
            <ListItem
                component={Link}
                to="/logout"
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>
        </List>
    );
};

export default ClientDrawer;
