import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { useDownloadImageMutation } from '../../../api/imageApi';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import { debounce } from 'lodash';

const ImageDisplay = ({ selectedImage }) => {

    // References
    const imageRef = useRef(null);
    const stageRef = useRef(null);
    const containerRef = useRef(null);

    // States to handle image state
    const [image, setImage] = useState(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [downloadImage] = useDownloadImageMutation();

    // States to handle scaling and position
    const [scale, setScale] = useState(1);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [stageKey, setStageKey] = useState(0);  // Forcing stage to re-render

    // Callback to update the stage size
    const updateStageSize = useCallback(() => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;
            setStageSize({ width: containerWidth, height: containerHeight });
            setStageKey((prevKey) => prevKey + 1);  // Trigger stage re-render

            // Explicitly force Konva to update its size
            if (stageRef.current) {
                stageRef.current.size({
                    width: containerWidth,
                    height: containerHeight,
                });
                stageRef.current.batchDraw();  // Redraw the stage
            }
        }
    }, []);

    useEffect(() => {
        // Debounce the updateStageSize to limit how often it's triggered
        const debouncedUpdateStageSize = debounce(() => {
            updateStageSize();
        }, 100);  // Adjust the delay as needed (100ms here)
    
        // Initialize ResizeObserver to watch for size changes on the container
        const observer = new ResizeObserver(() => {
            debouncedUpdateStageSize();
        });
    
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }
    
        // Clean up the observer on component unmount
        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
            observer.disconnect();
        };
    }, [updateStageSize]);

    // Handle image scale and position after image loads
    useEffect(() => {
        if (image && imageSize.width && imageSize.height) {
            const { width, height } = imageSize;
            const stageRatio = stageSize.width / stageSize.height;
            const imageRatio = width / height;

            let newScale = 1;
            let newPosition = { x: 0, y: 0 };

            if (stageRatio > imageRatio) {
                newScale = stageSize.height / height;
                newPosition = { x: (stageSize.width - width * newScale) / 2, y: 0 };
            } else {
                newScale = stageSize.width / width;
                newPosition = { x: 0, y: (stageSize.height - height * newScale) / 2 };
            }

            setScale(newScale);
            setPosition(newPosition);
        }
    }, [image, imageSize, stageSize]);

    // Convert blob to Image and set width and height
    const loadImage = useCallback((blob) => {
        const img = new window.Image();
        const url = URL.createObjectURL(blob);
        img.src = url;
        img.onload = () => {
            setImageSize({ width: img.width, height: img.height });
            setImage(img);
            URL.revokeObjectURL(url);
        };
    }, []);

    useEffect(() => {
        const fetchImage = async () => {
            if (selectedImage) {
                try {
                    const blob = await downloadImage(selectedImage.id).unwrap();
                    loadImage(blob);
                } catch (error) {
                    console.error("Error downloading image:", error);
                }
            }
        };

        fetchImage();
    }, [selectedImage, downloadImage, loadImage]);

    // Handle zooming with the mouse wheel
    const handleWheel = (e) => {
        e.evt.preventDefault();
        const scaleBy = 1.05;  // Zoom factor
        const stage = stageRef.current;
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();
        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
        newScale = Math.max(0.1, Math.min(10, newScale));

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        setScale(newScale);
        setPosition(newPos);
    };

    // Handle panning (dragging)
    const handleDragEnd = (e) => {
        setPosition({ x: e.target.x(), y: e.target.y() });
    };

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}>
            <Typography variant="h5" sx={{ textAlign: 'center', marginBottom: 2 }}>Preview</Typography>

            <Box ref={containerRef} sx={{ flexGrow: 1, bgcolor: "#cedfe5", borderRadius: '8px', overflow: 'hidden', border: '1px solid #cccccc' }}>
                <Stage
                    key={stageKey}
                    ref={stageRef}
                    width={stageSize.width}
                    height={stageSize.height}
                    scaleX={scale}
                    scaleY={scale}
                    x={position.x}
                    y={position.y}
                    onWheel={handleWheel}
                    draggable
                    onDragEnd={handleDragEnd}
                >
                    <Layer>
                        {image && (
                            <KonvaImage
                                image={image}
                                ref={imageRef}
                                width={imageSize.width}
                                height={imageSize.height}
                            />
                        )}
                    </Layer>
                </Stage>
            </Box>
        </Paper>
    );
};

export default ImageDisplay;
