import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  Typography,
  Pagination,
  ListItemText,
  Button,
  Chip,
} from '@mui/material';
import { useAppContext } from '../../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';

// New imports for filter functionality
import { Refresh, Clear, FilterList } from '@mui/icons-material';
import { useGetFilteredImagesQuery } from '../../../api/imageApi';
import FilterDialog from '../dialogs/FilterDialog';

// Existing imports continued
import usePaginatedJobs from '../../../hooks/usePaginatedJobs';
import { usePushJobContentMutation, useDeleteJobImageMutation } from '../../../api/jobApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const renderJobItem = (image, index, isCompleted) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <ListItemText
                primary={`${index}. ${image.name}`}
                sx={{ textDecoration: isCompleted ? 'line-through' : 'none' }} // Visual cue for completed tasks
            />
            {isCompleted ? (
                <Chip
                    icon={<CheckCircleOutlineIcon />}
                    label="Completed"
                    color="success"
                    size="small"
                    sx={{ marginLeft: 2 }}
                />
            ) : (
                <Chip
                    icon={<HourglassEmptyIcon />}
                    label="In Progress"
                    color="warning"
                    size="small"
                    sx={{ marginLeft: 2 }}
                />
            )}
        </Box>
    );
};

const renderProjectItem = (image, index, isAdded) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <ListItemText
                primary={`${index}. ${image.name}`}
                sx={{ textDecoration: isAdded ? 'line-through' : 'none' }} // Visual cue like a strikethrough if added
            />
            {isAdded && (
                <Chip
                    icon={<CheckCircleIcon />}
                    label="Added"
                    color="success"
                    size="small"
                    sx={{ marginLeft: 2 }}
                />
            )}
        </Box>
    );
};

const ImageJobsLists = ({ projectId, workerId, jobId, setSelectedImage, changeBlocked, changesToSave, setEnableValidation }) => {
    // Context
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);

    // States
    const [visibilityToggle, setVisibilityToggle] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);

    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [filters, setFilters] = useState([]);

    // Pagination for project images
    const [projectPage, setProjectPage] = useState(1);
    const projectLimit = 50; // Adjust as needed
    const projectOffset = (projectPage - 1) * projectLimit;

    // Clear selected image when projectId or jobId changes
    useEffect(() => {
        setSelectedImage(null);
    }, [projectId, jobId]);

    useEffect(() => {
        // If visibility toggle changes, reset the selected image
        if (visibilityToggle) {
            setEnableValidation(false);
        }
        else {
            setEnableValidation(true);
        }
    }, [visibilityToggle, setEnableValidation]); // eslint-disable-line react-hooks/exhaustive-deps

    // Selected Image IDs
    const [selectedProjectItemId, setSelectedProjectItemId] = useState(null);

    // Fetch project images with filters
    const {
    data: imageData,
    error: imageError,
    isLoading: imagesLoading,
    refetch: refetchImages,
    } = useGetFilteredImagesQuery(
    {
        projectId,
        limit: projectLimit,
        offset: projectOffset,
        filterRequest: { filters },
    },
    {
        skip: !projectId,
    }
    );

    const projectItems = imageData?.items || [];
    const projectTotalItems = imageData?.total || 0;

    // Job Images Information
    const {
        items: jobItems,
        totalItems: jobTotalItems,
        page: jobPage,
        limit: jobLimit,
        offset: jobOffset,
        selectedItemId: selectedJobItemId,
        setSelectedItemId: setSelectedJobItemId,
        handlePageChange: jobHandlePageChange,
        loading: jobLoading,
        imageIdList,
    } = usePaginatedJobs(jobId, refreshKey);

    // Mutations
    const [pushJobContentMutation] = usePushJobContentMutation();
    const [deleteJobImageMutation] = useDeleteJobImageMutation();

    // Handle Project Item Click
    const handleItemClick = async (itemId) => {
        if (changeBlocked) {
            const message1 = await openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }

        if (visibilityToggle === true) {
            setSelectedImage(projectItems.find((item) => item.id === itemId));
            setSelectedProjectItemId((prevSelected) => (prevSelected === itemId ? null : itemId));
            setSelectedJobItemId(null);
        } else {
            setSelectedImage(jobItems.find((item) => item.id === itemId));
            setSelectedJobItemId((prevSelected) => (prevSelected === itemId ? null : itemId));
            setSelectedProjectItemId(null);
        }
    };

    // Toggle visibility between project and job view
    const toggleView = async () => {
        if (changeBlocked) {
            const message1 = await openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }
        setVisibilityToggle((prev) => !prev);
    };

    // Handle the delete or add button click
    const handleButtonClick = async () => {
        if (changeBlocked) {
            const message1 = await openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }

        if (visibilityToggle === true) {
            // Add action
            if (jobId && selectedProjectItemId && projectId) {
                try {
                    const result = await pushJobContentMutation({
                        jobId: jobId,
                        newJobContent: {
                            imageId: selectedProjectItemId,
                            isCompleted: false,
                        },
                    });

                    if (result.error) {
                        const errorMessage = result.error.data?.detail || 'An unknown error occurred';
                        await openNotificationDialog('Error', errorMessage);
                    } else {
                        // Trigger refresh without clearing the list immediately
                        setRefreshKey((prevKey) => prevKey + 1);
                    }
                } catch (err) {
                    console.error('An unexpected error occurred:', err);
                    await openNotificationDialog('Error', 'An error occurred while adding the image to the job.');
                }
            } else {
                await openNotificationDialog('Error', 'An error occurred while adding the image to the job.');
            }
        } else {
            // Remove action
            if (jobId && selectedJobItemId && projectId) {
                try {
                    const result = await deleteJobImageMutation({
                        jobId: jobId,
                        imageId: selectedJobItemId,
                    });

                    if (result.error) {
                        const errorMessage = result.error.data?.detail || 'An unknown error occurred';
                        await openNotificationDialog('Error', errorMessage);
                    } else {
                        setRefreshKey((prevKey) => prevKey + 1);
                    }
                } catch (err) {
                    console.error('An unexpected error occurred:', err);
                    await openNotificationDialog('Error', 'An error occurred while deleting the image from the job.');
                }
            } else {
                await openNotificationDialog('Error', 'An error occurred while deleting the image from the job.');
            }
        }
    };

    // Extract just the imageIds from imageIdList
    const imageIdsInJob = imageIdList.map(item => item.imageId);

    // Filter projectItems to mark those that are already in the job
    const filteredProjectItems = projectItems.map((item) => ({
        ...item,
        isAdded: imageIdsInJob.includes(item.id), // Check if item.id is in imageIdsInJob
    }));

    // Merge isCompleted from imageIdList into jobItems
    const jobItemsWithStatus = jobItems.map((jobItem) => {
        const foundImage = imageIdList.find((image) => image.imageId === jobItem.id);
        return {
            ...jobItem,
            isCompleted: foundImage ? foundImage.isCompleted === 'true' : false,
        };
    });

    // Function to open the filter dialog
    const openFilterDialogHandler = () => {
        setFilterDialogOpen(true);
    };

    // Function to handle applied filters
    const handleApplyFilters = (appliedFilters) => {
        if (!projectId) {
        openNotificationDialog('Select Project', 'Please select a project first.');
        return;
        }
        setFilters(appliedFilters);
        setProjectPage(1);
    };

    // Handle reset filters
    const handleResetFilters = () => {
        if (!projectId) {
        openNotificationDialog('Select Project', 'Please select a project first.');
        return;
        }
        setFilters([]);
        setProjectPage(1);
    };

    // Handle refresh
    const handleRefresh = () => {
        if (!projectId) {
        openNotificationDialog('Select Project', 'Please select a project first.');
        return;
        }
        refetchImages();
    };

    // Handle page change for project images
    const projectHandlePageChange = (event, value) => {
        setProjectPage(value);
    };

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}>
            {visibilityToggle === true && (
                <>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                    Available Images
                    </Typography>

                    {/* Filter Buttons */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, gap: 1 }}>
                    <Button variant="outlined" onClick={handleRefresh} disabled={imagesLoading}>
                        <Refresh />
                    </Button>
                    <Button fullWidth variant="contained" onClick={openFilterDialogHandler} startIcon={<FilterList />}>
                        Filter
                    </Button>
                    <Button variant="outlined" onClick={handleResetFilters} disabled={filters.length === 0}>
                        <Clear />
                    </Button>
                    </Box>

                    {/* Project Images List */}
                    <Box
                    sx={{
                        flexGrow: 1,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        padding: 1,
                        overflowY: 'auto',
                        minHeight: 0,
                    }}
                    >
                    {imagesLoading ? (
                        <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                        Loading images...
                        </Typography>
                    ) : imageError ? (
                        <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                        Error loading images
                        </Typography>
                    ) : filteredProjectItems.length > 0 ? (
                        <List>
                        {filteredProjectItems.map((item, index) => (
                            <ListItem
                            key={item.id}
                            selected={item.id === selectedProjectItemId}
                            onClick={() => handleItemClick(item.id)}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: item.id === selectedProjectItemId ? 'primary.light' : 'inherit',
                                '&:hover': {
                                backgroundColor: 'primary.highlight',
                                color: 'white',
                                },
                                transition: 'background-color 0.3s ease',
                                borderRadius: '4px',
                            }}
                            >
                            {renderProjectItem(item, index + 1 + projectOffset, item.isAdded)}
                            </ListItem>
                        ))}
                        </List>
                    ) : (
                        <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                        No items to display
                        </Typography>
                    )}
                    </Box>

                    {/* Total Items and Pagination */}
                    <Typography sx={{ marginTop: '2px', marginBottom: 0, textAlign: 'right' }}>
                    Total: {projectTotalItems}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                    <Pagination
                        count={Math.ceil(projectTotalItems / projectLimit)}
                        page={projectPage}
                        onChange={projectHandlePageChange}
                        color="primary"
                    />
                    </Box>

                    {/* Filter Dialog */}
                    <FilterDialog
                        key={selectedProjectItemId}
                        projectId={projectId}
                        open={filterDialogOpen}
                        onClose={() => setFilterDialogOpen(false)}
                        onApplyFilters={handleApplyFilters}
                        initialFilters={filters}
                    />
                </>
                )}

            {visibilityToggle === false && (
                <>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                        Available Job Images
                    </Typography>
                    <Box sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 2, padding: 1, overflowY: 'auto', minHeight: 0 }}>
                        <List>
                        {jobItemsWithStatus.length > 0 ? (
                            jobItemsWithStatus.map((item, index) => (
                                <ListItem
                                    key={item.id}
                                    selected={item.id === selectedJobItemId}
                                    onClick={() => handleItemClick(item.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundColor: item.id === selectedJobItemId ? 'primary.light' : 'inherit',
                                        '&:hover': {
                                            backgroundColor: 'primary.highlight',
                                            color: 'white',
                                        },
                                        transition: 'background-color 0.3s ease',
                                        borderRadius: '4px',
                                    }}
                                >
                                    {renderJobItem(item, index + 1 + jobOffset, item.isCompleted)}
                                </ListItem>
                            ))
                        ) : (
                            <Typography sx={{ textAlign: 'center', padding: '16px' }}>No items to display</Typography>
                        )}
                        </List>
                    </Box>
                    <Typography sx={{ marginTop: '2px', marginBottom: 0, textAlign: 'right' }}>
                        Total: {jobTotalItems}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                        <Pagination count={Math.ceil(jobTotalItems / jobLimit)} page={jobPage} onChange={jobHandlePageChange} color="primary" />
                    </Box>
                </>
            )}

            {/* Buttons at the bottom */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleView}
                    sx={{ width: '48%' }}
                >
                    {visibilityToggle ? 'Project' : 'Job'}
                </Button>
                <Button
                    variant="contained"
                    color={visibilityToggle ? 'success' : 'error'}
                    onClick={handleButtonClick}
                    sx={{ width: '48%' }}
                >
                    {visibilityToggle ? 'Add' : 'Remove'}
                </Button>
            </Box>
        </Paper>
    );
};

export default ImageJobsLists;
