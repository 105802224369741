import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/project`,
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the project API
export const projectApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "projectApi",
    tagTypes: ["Project"],

    endpoints: (build) => ({

        // Get all projects with pagination support
        getAllProjects: build.query({
            query: ({ limit, offset}) => ({
                url: `/?limit=${limit}&offset=${offset}`,
                method: "GET",
            }),
            providesTags: ["Project"],
        }),

        // Delete a project by email
        deleteProject: build.mutation({
            query: (projectId) => ({
                url: `/${projectId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Project"],
        }),

        // Create a new project
        createProject: build.mutation({
            query: (newProject) => ({
                url: "/",
                method: "POST",
                body: newProject,
            }),
            invalidatesTags: ["Project"],
        }),

    }),
});

// Export hooks for using the project API
export const {
    useCreateProjectMutation,
    useGetAllProjectsQuery,
    useDeleteProjectMutation,
} = projectApi;
