import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/user`,
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the user API
export const userApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "userApi",
    tagTypes: ["User"],

    endpoints: (build) => ({

        // Get all users with pagination support
        getAllUsers: build.query({
            query: ({ limit, offset}) => ({
                url: `/?limit=${limit}&offset=${offset}`,
                method: "GET",
            }),
            providesTags: ["User"],
        }),

        // Delete a user by email
        deleteUser: build.mutation({
            query: (userId) => ({
                url: `/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["User"],
        }),

        // Create a new user
        createUser: build.mutation({
            query: (newUser) => ({
                url: "/",
                method: "POST",
                body: newUser,
            }),
            invalidatesTags: ["User"],
        }),

    }),
});

// Export hooks for using the user API
export const {
    useCreateUserMutation,
    useGetAllUsersQuery,
    useDeleteUserMutation,
} = userApi;
