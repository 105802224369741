import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/image`, // Base URL for images
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the image API
export const imageApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "imageApi",
    tagTypes: ["Image"],

    endpoints: (build) => ({

        // Get all images with pagination support and projectId
        getAllImages: build.query({
            query: ({ projectId, limit, offset }) => ({
                url: `/${projectId}?limit=${limit}&offset=${offset}`,
                method: "GET",
            }),
            providesTags: ["Image"],
        }),

        // Get all images with pagination support and projectId
        getImageInformation: build.query({
            query: ({ imageId }) => ({
                url: `/imageInformation/${imageId}`,
                method: "GET",
            }),
            providesTags: ["Image"],
        }),

        // Get all images with pagination support and projectId
        getFilteredImages: build.query({
            query: ({ projectId, limit, offset, filterRequest }) => ({
                url: `filtered/${projectId}?limit=${limit}&offset=${offset}`,
                method: "POST",
                body: filterRequest,
            }),
            providesTags: ["Image"],
        }),

        // Download an image by id as a mutation
        downloadImage: build.mutation({
            query: (imageId) => ({
                url: `/downloadImage/${imageId}`,
                method: "GET",
                responseHandler: (response) => response.blob(),
            }),
        }),

        // Download an annotation by image id
        downloadAnnotation: build.mutation({
            query: (imageId) => ({
                url: `/downloadAnnotation/${imageId}`,
                method: "GET",
                responseHandler: (response) => response.json(),  // Handle as JSON response
            }),
        }),

        // Upload a new annotation file for an image
        uploadAnnotation: build.mutation({
            query: ({ imageId, formData }) => ({
                url: `/uploadAnnotation/${imageId}`,
                method: "POST",
                body: formData,
                headers: {
                    // Content-Type is automatically set to multipart/form-data by the browser
                },
            }),
            invalidatesTags: ["Image"],
        }),

        // Delete an image by id
        deleteImage: build.mutation({
            query: (imageId) => ({
                url: `/${imageId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Image"],
        }),

        // Create a new image
        createImage: build.mutation({
            query: ({ projectId, formData }) => ({
                url: `/${projectId}`,
                method: "POST",
                body: formData,
                headers: {
                    // Content-Type is automatically set to multipart/form-data by the browser
                },
            }),
            invalidatesTags: ["Image"],
        }),

        // Edit an image by id
        editImage: build.mutation({
            query: (editImage) => ({
                url: `/${editImage.imageId}`,
                method: "PUT",
                body: editImage,
            }),
            invalidatesTags: ["Image"],
        }),

        // Get image statistics for a project
        getStats: build.query({
            query: (projectId) => ({
                url: `/stats/${projectId}`,
                method: "GET",
            }),
            providesTags: ["Stats"],
        }),

    }),
});

// Export hooks for using the image API
export const {
    useCreateImageMutation,
    useGetAllImagesQuery,
    useGetImageInformationQuery,
    useDownloadImageMutation,
    useDownloadAnnotationMutation,
    useUploadAnnotationMutation,
    useDeleteImageMutation,
    useEditImageMutation,
    useGetStatsQuery,
    useGetFilteredImagesQuery,
} = imageApi;
