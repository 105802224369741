import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/progress`, // Base URL for progress
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the progress API
export const progressApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "progressApi",
    tagTypes: ["Progress"],

    endpoints: (build) => ({

        // Get progress for a specific image and project from the request body
        getProgress: build.query({
            query: ({ projectId, imageId }) => ({
                url: `/getProgress`,
                method: "POST",
                body: { projectId, imageId },
            }),
            providesTags: ["Progress"],
        }),

        // Upload progress for a specific image and project
        uploadProgress: build.mutation({
            query: (progressData) => ({
                url: "/uploadProgress",
                method: "POST",
                body: progressData,
            }),
            invalidatesTags: ["Progress"],
        }),

        // Get progress statistics for a project
        getProgressStats: build.query({
            query: (projectId) => ({
                url: `/stats/${projectId}`,
                method: "GET",
            }),
            providesTags: ["Stats"],
        }),
    }),
});

// Export hooks for using the progress API
export const {
    useGetProgressQuery,
    useUploadProgressMutation,
    useGetProgressStatsQuery,
} = progressApi;
