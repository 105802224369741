import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

// Custom medical order for the variants
const medicalOrder = [
     'N/A', '18', '17', '16', '15', '14', '13', '12', '11', '21', '22', '23', '24', '25', '26', '27', '28',
    '48', '47', '46', '45', '44', '43', '42', '41', '31', '32', '33', '34', '35', '36', '37', '38'
];

// Helper function to extract the base name (before the comma)
const getBaseName = (name) => {
    return name.includes(',') ? name.split(',')[0].trim() : name.trim();
};

// Helper function to extract the variant (after the comma)
const getVariant = (name) => {
    return name.includes(',') ? name.split(',')[1]?.trim() : '';
};

// Sorting function to order variants based on medicalOrder array
const sortByMedicalOrder = (a, b) => {
    const indexA = medicalOrder.indexOf(a);
    const indexB = medicalOrder.indexOf(b);

    // If both are found in the medicalOrder array, sort by that order
    if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
    }
    // Otherwise, maintain natural order (for any cases not in the array)
    return a.localeCompare(b);
};

const LabelSelection = ({ labels, isDrawing, isEditing, setSelectedLabel, setDrawingMode }) => {
    const [selectedBaseName, setSelectedBaseName] = useState('');
    const [selectedVariant, setSelectedVariant] = useState('');
    const [isVariantRequired, setIsVariantRequired] = useState(false);  // Track if variant is required

    // Group labels by base name
    const groupedLabels = labels.reduce((acc, label) => {
        const baseName = getBaseName(label.name);
        if (!acc[baseName]) {
            acc[baseName] = [];
        }
        acc[baseName].push(label);
        return acc;
    }, {});

    // Handle base name selection
    const handleBaseNameChange = (event) => {
        const baseName = event.target.value;
        setSelectedBaseName(baseName);
        setSelectedVariant('');  // Reset the variant when base name changes

        const labelGroup = groupedLabels[baseName];

        if (labelGroup.length === 1) {
            // If only one label exists for this base name, select it automatically
            const label = labelGroup[0];
            setSelectedLabel(label);
            setDrawingMode(label.type);
            setIsVariantRequired(false);  // No variant required
        } else {
            // Multiple variants exist, so require the user to select a variant
            setSelectedLabel(null);  // Clear the selected label until both are chosen
            setIsVariantRequired(true);  // Variant is required
        }
    };

    // Handle variant selection
    const handleVariantChange = (event) => {
        const variant = event.target.value;
        setSelectedVariant(variant);

        // Find the selected label by combining base name and variant
        const selectedLabel = groupedLabels[selectedBaseName].find(
            (label) => label.name === `${selectedBaseName},${variant}`
        );

        if (selectedLabel) {
            setSelectedLabel(selectedLabel);
            setDrawingMode(selectedLabel.type);
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {/* Base Name Selection */}
            <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="base-label-select-label">Base Label</InputLabel>
                <Select
                    labelId="base-label-select-label"
                    id="base-label-select"
                    value={selectedBaseName}
                    label="Base Label"
                    onChange={handleBaseNameChange}
                    disabled={isDrawing || isEditing}
                    renderValue={(selected) => {
                        if (!selected) return '';
                        return (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        width: 16,
                                        height: 16,
                                        backgroundColor: groupedLabels[selected][0].color,
                                        borderRadius: '50%',
                                        marginRight: 1,
                                    }}
                                />
                                {selected}
                            </Box>
                        );
                    }}
                >
                    {Object.keys(groupedLabels).map((baseName) => (
                        <MenuItem key={baseName} value={baseName}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                    sx={{
                                        width: 16,
                                        height: 16,
                                        backgroundColor: groupedLabels[baseName][0].color,
                                        borderRadius: '50%',
                                        marginRight: 1,
                                    }}
                                />
                                {baseName}
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Variant Selection (if multiple variants exist) */}
            {isVariantRequired && (
                <FormControl sx={{ minWidth: 100 }} size="small">
                    <InputLabel id="variant-select-label">Variant</InputLabel>
                    <Select
                        labelId="variant-select-label"
                        id="variant-select"
                        value={selectedVariant}
                        label="Variant"
                        onChange={handleVariantChange}
                        disabled={isDrawing || isEditing}
                        renderValue={(selected) => (selected ? selected : '')}
                    >
                        {groupedLabels[selectedBaseName]
                            .map((label) => getVariant(label.name))  // Extract variants
                            .sort(sortByMedicalOrder)  // Sort by medical order
                            .map((variant) => (
                                <MenuItem key={variant} value={variant}>
                                    {variant}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};

export default LabelSelection;
