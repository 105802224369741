import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const AddProjectDialog = ({ open, onClose, onCreate }) => {
    // Local state to manage project name
    const [projectName, setProjectName] = useState('');
    const [isProjectNameValid, setIsProjectNameValid] = useState(false);

    // Simple validation: Project name should not be empty
    const validateProjectName = (name) => {
        return name.trim().length > 0;
    };

    // Handle changes to the project name field
    const handleProjectNameChange = (e) => {
        const value = e.target.value;
        setProjectName(value);
        setIsProjectNameValid(validateProjectName(value));
    };

    // Handle the creation of the project
    const handleCreate = () => {
        onCreate({ name: projectName });
        onClose();

        // Clear the form
        setProjectName('');
    };

    // Button should be enabled only if the project name is valid
    const isFormValid = isProjectNameValid;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="add-project-dialog-title"
            aria-describedby="add-project-dialog-description"
        >
            <DialogTitle id="add-project-dialog-title">Add New Project</DialogTitle>
            <DialogContent>

                {/* Project name field */}
                <TextField
                    margin="dense"
                    label="Project Name"
                    fullWidth
                    value={projectName}
                    onChange={handleProjectNameChange}
                    error={!isProjectNameValid && projectName.length > 0}
                    helperText={!isProjectNameValid && projectName.length > 0 ? "Project name is required" : ""}
                />

            </DialogContent>
            <DialogActions>
                {/* Cancel button */}
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>

                {/* Create button, disabled if form is invalid */}
                <Button onClick={handleCreate} color="primary" autoFocus disabled={!isFormValid}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddProjectDialog;
