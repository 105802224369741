import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import ClientExplorerList from '../../components/common/lists/ClientExplorerList';
import AnnotationPanel from '../../components/common/image/AnnotationPanel';

import { useEditJobContentMutation } from '../../api/jobApi';
import { useGetImageInformationQuery } from '../../api/imageApi';
import ImageMetaData from '../../components/common/image/ImageMetaData';

const Annotation = () => {
    // Project and Images
    const [projectId, setProjectId] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [changesToSave, setChangesToSave] = useState(false);
    const [changeBlocked, setChangeBlocked] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    // Mutation to edit job content
    const [editJobContent] = useEditJobContentMutation();

    // Query all the time when selectedImage changes (skip if it is null)
    const { data: imageInformation } = useGetImageInformationQuery({imageId: selectedImage}, { skip: !selectedImage });

    // Handle isCompleted
    const handleIsCompleted = async (value) => {
        try {
            const result = await editJobContent({ jobId: selectedJobId, newJobContent: { imageId: selectedImage, isCompleted: !isCompleted } });
            if (result.error) {
                console.error(result.error);
                return;
            } else{
                setIsCompleted(prev => !prev);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        // Grid layout of 4 by 4
        <Box display="grid" flexGrow={1} gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(12, 1fr)" gap={2} sx={{ height: '100%', minHeight: 0 }}>

            {/* Image Selector */}
            <Box gridColumn="span 3" gridRow="span 9" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <ClientExplorerList
                    setProjectId={setProjectId}
                    setSelectedJobId={setSelectedJobId}
                    setSelectedImage={setSelectedImage}
                    changesToSave={changesToSave}
                    changeBlocked={changeBlocked}
                    setIsCompleted={setIsCompleted}
                />
            </Box>

            {/* Image Metadate */}
            <Box gridColumn="span 3" gridRow="10 / span 3" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <ImageMetaData
                    selectedImage={imageInformation? imageInformation : null}
                />
            </Box>

            {/* Example: Another box that spans 3 columns */}
            <Box gridColumn="span 9" gridRow="span 12" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <AnnotationPanel
                    selectedImage={selectedImage}
                    projectId={projectId}
                    setPendingChanges={setChangesToSave}
                    setChangeBlocked={setChangeBlocked}
                    enableValidation={false}
                    enableIsCompleted={true}
                    isCompleted={isCompleted}
                    handleIsCompleted={handleIsCompleted}
                />
            </Box>

        </Box>
    );
};

export default Annotation;
