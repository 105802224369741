import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, styled, Divider, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useLocation } from 'react-router-dom';
import { drawerWidth } from './config';
import { useTheme } from '@mui/material/styles';
import AdminDrawer from './AdminDrawer';
import ClientDrawer from './ClientDrawer';
import { useSelector } from 'react-redux';
import { useAppContext } from '../../utils/ApplicationProvider';


// Drawer header
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: theme.palette.background.sidebarHeader,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));

const Sidebar = ({ open, handleDrawerClose }) => {
    // States
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const location = useLocation();
    const theme = useTheme();

    // Check if the usere is an admin
    const isAdmin = user.admin;

    // JSX
    return (
        // Drawer component
        <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: theme.palette.background.sidebar,
                },
            }}
        >
            {/* Drawer header */}
            <DrawerHeader>
                <Typography variant="h6" sx={{ color: theme.palette.background.sidebarText, paddingLeft: theme.spacing(2) }}>
                    Menu
                </Typography>
                <IconButton onClick={handleDrawerClose} sx={{ color: theme.palette.background.sidebarText }}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>

            {/* Divider */}
            <Divider />

            {/* Drawer content */}
            {isAdmin ? <AdminDrawer /> : <ClientDrawer />}
        </Drawer>
    );
};

export default Sidebar;