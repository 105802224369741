import React, { useState } from 'react';
import { Box, Tooltip, ToggleButton, Menu, Typography, IconButton, LinearProgress } from '@mui/material';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const ContrastBrightnessAdjuster = ({ contrast, setContrast, brightness, setBrightness, isDrawing, isEditing }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleOpenMenu = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleBrightnessChange = (amount) => {
        setBrightness((prevBrightness) => {
            const newValue = prevBrightness + amount;
            return Math.min(0.6, Math.max(-0.6, newValue)); // Ensure it stays within the bounds
        });
    };

    const handleContrastChange = (amount) => {
        setContrast((prevContrast) => {
            const newValue = prevContrast + amount;
            return Math.min(100, Math.max(-100, newValue)); // Ensure it stays within the bounds
        });
    };

    // Convert brightness and contrast values to a range suitable for the progress bar (0 to 100)
    const getBrightnessProgress = () => ((brightness + 0.6) / 1.2) * 100;
    const getContrastProgress = () => ((contrast + 100) / 200) * 100;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Contrast and Brightness Adjustment Icon */}
            <Tooltip title="Adjust Contrast & Brightness">
                <ToggleButton
                    value="contrast-brightness"
                    selected={Boolean(menuAnchorEl)}
                    onClick={handleOpenMenu}
                    disabled={isDrawing || isEditing}
                    sx={{
                        backgroundColor: '#115293',
                        color: '#fff',
                        '&.Mui-selected': {
                            backgroundColor: '#115293',
                            color: '#fff',
                        },
                        '&:hover': {
                            backgroundColor: '#115293',
                        },
                        border: '1px solid',
                        borderColor: '#115293',
                        borderRadius: '8px',
                        padding: '8px',
                    }}
                >
                    <BrightnessHighIcon />
                </ToggleButton>
            </Tooltip>

            {/* Menu with Buttons for Adjustments and Progress Bar */}
            <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleCloseMenu}>
                <Box sx={{ padding: 2, width: 300 }}>
                    <Typography gutterBottom>Brightness</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <IconButton onClick={() => handleBrightnessChange(-0.05)}>
                            <RemoveIcon />
                        </IconButton>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <LinearProgress variant="determinate" value={getBrightnessProgress()} />
                        </Box>
                        <IconButton onClick={() => handleBrightnessChange(0.05)}>
                            <AddIcon />
                        </IconButton>
                    </Box>

                    <Typography gutterBottom sx={{ mt: 2 }}>Contrast</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <IconButton onClick={() => handleContrastChange(-5)}>
                            <RemoveIcon />
                        </IconButton>
                        <Box sx={{ width: '100%', mx: 2 }}>
                            <LinearProgress variant="determinate" value={getContrastProgress()} />
                        </Box>
                        <IconButton onClick={() => handleContrastChange(5)}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Menu>
        </Box>
    );
};

export default ContrastBrightnessAdjuster;
