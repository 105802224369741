import { createTheme } from "@mui/material/styles";
import { blue, lightBlue, red } from "@mui/material/colors";

// Create a custom MUI theme for a medical app
const theme = createTheme({
    palette: {
        primary: {
            main: blue[700], // Medical blue for primary elements
            highlight: lightBlue[200], // Light blue for highlights
            light: lightBlue[100], // Light blue for backgrounds or accents
            dark: blue[900], // Darker blue for headers or strong accents
        },
        secondary: {
            main: '#6d4c41', // Brown for secondary accents (more neutral)
        },
        success: {
            main: '#4caf50', // Standard green for success
        },
        error: {
            main: red[400], // Softer red for error states
        },
        background: {
            default: '#f5faff', // Light blue-gray for overall background
            sidebar: '#3b4a6b', // Darker medical blue for sidebar
            sidebarHeader: '#2e3b55', // Dark blue for sidebar header
            sidebarText: '#ffffff', // White text for sidebar
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 300,
            color: blue[900], // Darker medical blue for headings
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 300,
            color: blue[900], // Matching the h1 color
        },
        body1: {
            fontSize: '1rem',
            color: '#4f4f4f', // Neutral text color
        },
    },
});

export default theme;
