import React, { useState, useEffect } from 'react';
import { Paper, MenuItem, Select, FormControl, InputLabel, Box} from '@mui/material';
import { useAppContext } from '../../../utils/ApplicationProvider';
import useAllProjects from '../../../hooks/useAllProjects';
import useAllUsers from '../../../hooks/useAllUsers';
import useAllJobs from '../../../hooks/useAllJobs';

const UserProjectJobSelector = ({ setProjectId, setJobId, setWorkerId, changesToSave, changeBlocked }) => {
    // Context
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();

    // States
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(null);

    // Hooks
    const { users } = useAllUsers();
    const { projects } = useAllProjects();
    const { jobs } = useAllJobs(selectedProjectId, selectedUserId);

    // Handle project selection change
    const handleProjectChange = async (event) => {
        // If changeBlocked is true, prevent user from selecting another item
        if (changeBlocked) {
            openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        // If changesToSave is true, prompt user to save changes
        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }

        const projectId = event.target.value;
        setSelectedProjectId(projectId);
        setProjectId(projectId);
    };

    // Handle user selection change
    const handleUserChange = async (event) => {
        // If changeBlocked is true, prevent user from selecting another item
        if (changeBlocked) {
            openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        // If changesToSave is true, prompt user to save changes
        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }

        const userId = event.target.value;
        setSelectedUserId(userId);
        setWorkerId(userId);
    }

    // Handle job selection change
    const handleJobChange = async (event) => {
        // If changeBlocked is true, prevent user from selecting another item
        if (changeBlocked) {
            openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        // If changesToSave is true, prompt user to save changes
        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }

        const jobId = event.target.value;
        setSelectedJobId(jobId);
        setJobId(jobId);
    }

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 0, padding: 2 }}>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                {/* User Selector */}
                <FormControl fullWidth >
                    <InputLabel id="project-selector-label">Select User</InputLabel>
                    <Select
                        labelId="user-selector-label"
                        value={selectedUserId? selectedUserId : ''}
                        onChange={handleUserChange}
                        label="Select User"
                    >
                        {users?.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {user.email}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2, gap:2 }}>
                {/* Project Selector */}
                <FormControl fullWidth >
                    <InputLabel id="project-selector-label">Select Project</InputLabel>
                    <Select
                        labelId="project-selector-label"
                        value={selectedProjectId? selectedProjectId : ''}
                        onChange={handleProjectChange}
                        label="Select Project"
                    >
                        {projects?.map((project) => (
                            <MenuItem key={project.id} value={project.id}>
                                {project.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Job Selector */}
                <FormControl fullWidth >
                    <InputLabel id="job-selector-label">Select Job</InputLabel>
                    <Select
                        labelId="job-selector-label"
                        value={selectedJobId? selectedJobId : ''}
                        onChange={handleJobChange}
                        label="Select Job"
                    >
                        {jobs?.map((job) => (
                            <MenuItem key={job.id} value={job.id}>
                                {job.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Paper>
    );
};

export default UserProjectJobSelector;
