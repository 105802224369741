import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box } from '@mui/material';
import { ChromePicker } from 'react-color'; // Importing ChromePicker from react-color

const EditLabelDialog = ({ open, onClose, onSave, labelName, labelType, initialColor = '#ffffff' }) => {
    // Local state to manage the color
    const [color, setColor] = useState(initialColor); // Initial color for the color picker

    // Set color when dialog opens or initialColor changes
    useEffect(() => {
        setColor(initialColor);
    }, [initialColor]);

    // Handle color change from the color picker (while dragging)
    const handleColorChange = (newColor) => {
        setColor(newColor.hex); // Update color as hex value
    };

    // Handle saving the updated label
    const handleSave = () => {
        onSave({ color }); // Only color is passed to onSave
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="edit-label-dialog-title"
            aria-describedby="edit-label-dialog-description"
        >
            <DialogTitle id="edit-label-dialog-title">Edit Label</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* Displaying the name, not editable */}
                    <TextField
                        margin="dense"
                        label="Name"
                        type="text"
                        value={labelName}
                        variant="standard" // Reduce the visual weight of the TextField
                        disabled
                    />

                    {/* Displaying the type, not editable */}
                    <TextField
                        margin="dense"
                        label="Type"
                        type="text"
                        value={labelType}
                        variant="standard" // Reduce the visual weight of the TextField
                        disabled
                    />

                    {/* Color picker */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <ChromePicker color={color} onChange={handleColorChange} />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {/* Cancel button */}
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>

                {/* Save button */}
                <Button onClick={handleSave} color="primary" autoFocus>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditLabelDialog;
