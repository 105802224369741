import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, MenuItem, IconButton, TextField, FormControl, Select, InputLabel, Autocomplete } from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import useAllLabels from '../../../hooks/useAllLabels';
import { tables, fields, operators } from '../../../utils/filterUtils';

// Filter Dialog Component
const FilterDialog = ({ open, onClose, onApplyFilters, projectId, initialFilters }) => {
    const { labels, loading: labelsLoading } = useAllLabels(projectId);
    const [filters, setFilters] = useState(
        initialFilters ? JSON.parse(JSON.stringify(initialFilters)) : []
    );

    useEffect(() => {
        if (open) {
        setFilters(initialFilters ? JSON.parse(JSON.stringify(initialFilters)) : []);
        }
    }, [open, initialFilters]);

    // Add a new empty filter
    const addFilter = () => {
        setFilters((prevFilters) => [
        ...prevFilters,
        {
            table: '',
            field: '',
            operator: '',
            value: '',
            foreignField: '',
            foreignValue: '',
        },
        ]);
    };

    // Remove a filter at a specific index
    const removeFilter = (index) => {
        setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
    };

    // Handle changes in filter fields
    const handleFilterChange = (index, field, value) => {
        setFilters((prevFilters) =>
        prevFilters.map((filter, i) => {
            if (i !== index) {
            return filter;
            }

            // Create a new filter object
            let updatedFilter = { ...filter, [field]: value };

            // Reset dependent fields when table or field changes
            if (field === 'table') {
            updatedFilter = {
                ...updatedFilter,
                field: '',
                operator: '',
                value: '',
                foreignField: '',
                foreignValue: '',
            };
            } else if (field === 'field') {
            updatedFilter = {
                ...updatedFilter,
                operator: '',
                value: '',
            };
            }

            return updatedFilter;
        })
        );
    };

    // Handle Apply Filters
    const handleApplyFilters = () => {
        // Pass filters back to parent component
        filters.forEach((filter) => {
            if (fields[filter.table]) {
                const selectedField = fields[filter.table].find(
                (f) => f.value === filter.field
                );
                if (selectedField?.type === 'number') {
                filter.value = parseFloat(filter.value);
                }
            }
        });
        onApplyFilters(filters);
        onClose();
    };

    // Render filter rows
    const renderFilters = () => {
        return filters.map((filter, index) => {
        // Get available fields based on selected table
        const availableFields = fields[filter.table] || [];

        // Get field type
        const selectedField = availableFields.find(
            (f) => f.value === filter.field
        );
        const fieldType = selectedField?.type;

        // Get available operators based on field type
        const availableOperators = operators[fieldType] || [];

        return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2, flexWrap: 'wrap' }}>
                {/* Table Selection */}
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Table</InputLabel>
                    <Select
                    value={filter.table}
                    label="Table"
                    onChange={(e) =>
                        handleFilterChange(index, 'table', e.target.value)
                    }
                    >
                    {tables.map((table) => (
                        <MenuItem key={table} value={table}>
                        {table}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                {/* Field Selection */}
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel>Field</InputLabel>
                    <Select
                    value={filter.field}
                    label="Field"
                    onChange={(e) =>
                        handleFilterChange(index, 'field', e.target.value)
                    }
                    disabled={!filter.table}
                    >
                    {availableFields.map((field) => (
                        <MenuItem key={field.value} value={field.value}>
                        {field.label}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                {/* Operator Selection */}
                <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel>Operator</InputLabel>
                    <Select
                    value={filter.operator}
                    label="Operator"
                    onChange={(e) =>
                        handleFilterChange(index, 'operator', e.target.value)
                    }
                    disabled={!filter.field}
                    >
                    {availableOperators.map((op) => (
                        <MenuItem key={op.value} value={op.value}>
                        {op.label}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                {/* Value Input */}
                {fieldType === 'boolean' ? (
                    <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Value</InputLabel>
                    <Select
                        value={filter.value}
                        label="Value"
                        onChange={(e) =>
                        handleFilterChange(index, 'value', e.target.value)
                        }
                        disabled={!filter.operator}
                    >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                    </Select>
                    </FormControl>
                ) : fieldType === 'number' ? (
                    <TextField
                    type="number"
                    label="Value"
                    value={filter.value}
                    onChange={(e) =>
                        handleFilterChange(index, 'value', e.target.value)
                    }
                    disabled={!filter.operator}
                    />
                ) : (
                    <TextField
                    label="Value"
                    value={filter.value}
                    onChange={(e) =>
                        handleFilterChange(index, 'value', e.target.value)
                    }
                    disabled={!filter.operator}
                    />
                )}

                {/* Foreign Field and Value (if applicable) */}
                {filter.table !== 'Image' && (
                    <>
                        <FormControl sx={{ minWidth: 150 }}>
                            <InputLabel>Foreign Field</InputLabel>
                            <Select
                            value={filter.foreignField}
                            label="Foreign Field"
                            onChange={(e) =>
                                handleFilterChange(index, 'foreignField', e.target.value)
                            }
                            disabled={!filter.value}
                            >
                            {/* For simplicity, assume foreign fields are 'labelId' */}
                            <MenuItem value="labelId">Label ID</MenuItem>
                            </Select>
                        </FormControl>

                        {filter.foreignField === 'labelId' && (
                        <Autocomplete
                            options={labels}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            loading={labelsLoading}
                            value={labels.find(label => label.id === filter.foreignValue) || null} // Controlled value
                            onChange={(event, newValue) =>
                                handleFilterChange(
                                    index,
                                    'foreignValue',
                                    newValue?.id || ''
                                )
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Label"
                                    variant="outlined"
                                    disabled={!filter.foreignField}
                                />
                            )}
                            sx={{ minWidth: 200 }}
                        />
                    )}
                    </>
                )}

                {/* Remove Filter Button */}
                <IconButton onClick={() => removeFilter(index)} color="error">
                    <RemoveCircle />
                </IconButton>
            </Box>
        );
        });
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="filter-dialog-title" fullWidth maxWidth="lg">
        <DialogTitle id="filter-dialog-title">Configure Filters</DialogTitle>
        <DialogContent dividers sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
            {/* Filters */}
            {renderFilters()}

            {/* Add Filter Button */}
            <Button startIcon={<AddCircle />} onClick={addFilter} variant="contained" color="primary" sx={{ mt: 2 }} >
                Add Filter
            </Button>
        </DialogContent>

        <DialogActions>
            <Button onClick={onClose} color="secondary">
                Cancel
            </Button>
            <Button onClick={handleApplyFilters} color="primary" disabled={filters.length === 0}>
                Apply Filters
            </Button>
        </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
