import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './components/structure/Layout';
import Login from './pages/Login';

import AdminDashboard from './pages/admin/Dashboard';
import UsersManagement from './pages/admin/Users';
import ProjectsManagement from './pages/admin/Projects';
import AnnotationManagement from './pages/admin/Annotation';
import JobsManagement from './pages/admin/Jobs';

import JobsClient from './pages/client/Jobs';

const App = () => {
    // Get the state from the context
    const { user, isAuthenticated } = useSelector((state) => state.auth);

    // JSX
    return (

        <Router>
            <Layout>
                <Routes>
                    {/* Login route */}
                    <Route path="/login" element={isAuthenticated ? user.admin? <Navigate to="/admin/dashboard" /> : <Navigate to="/client/jobs" /> : <Login />} />

                    {/* Admin routes */}
                    <Route path="/admin/dashboard" element={isAuthenticated ? <AdminDashboard /> : <Navigate to="/login" /> }/>
                    <Route path="/admin/users" element={isAuthenticated ? <UsersManagement /> : <Navigate to="/login" /> }/>
                    <Route path="/admin/projects" element={isAuthenticated ? <ProjectsManagement /> : <Navigate to="/login" /> }/>
                    <Route path="/admin/annotation" element={isAuthenticated ? <AnnotationManagement /> : <Navigate to="/login" /> }/>
                    <Route path="/admin/jobs" element={isAuthenticated ? <JobsManagement /> : <Navigate to="/login" /> }/>

                    {/* Client routes */}
                    <Route path="/client/jobs" element={isAuthenticated ? <JobsClient /> : <Navigate to="/login" /> }/>

                    {/* Default route */}
                    <Route path="*" element={isAuthenticated? user.admin? <Navigate to="/admin/dashboard" /> : <Navigate to="/client/jobs" /> : <Navigate to="/login" />} />
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;
