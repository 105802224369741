import React, { useState, useEffect } from 'react';
import { Box, Paper, List, ListItem, Typography, Pagination, Button, MenuItem, Select, FormControl, InputLabel, ListItemText } from '@mui/material';

import { useAppContext } from '../../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';

import useWorkerJobs from '../../../hooks/useWorkerJobs';
import usePaginatedJobs from '../../../hooks/usePaginatedJobs';

const renderItem = (image, index) => {
    return (
        <ListItemText primary={`${index}. ${image.name}`}/>
    );
};

const ClientExplorerList = ({ setProjectId, setSelectedJobId, setSelectedImage, changesToSave, changeBlocked, setIsCompleted }) => {
    // Context
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);

    // Projects
    const { jobs } = useWorkerJobs(user.id);
    const [jobId, setJobId] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    // Images
    const { items, totalItems, page, limit, offset, selectedItemId, setSelectedItemId, handlePageChange, loading, imageIdList } = usePaginatedJobs(jobId, refreshKey);

    // Handle project selection change
    const handleJobChange = (event) => {
        // Set the job id
        const jobId = event.target.value;
        setJobId(jobId);
        setSelectedJobId(jobId);

        // Find the item with that id
        const job = jobs.find((job) => job.id === jobId);
        setSelectedProjectId(job.projectId);
        setProjectId(job.projectId);

        // Reset the selected image
        setSelectedItemId(null);
        handlePageChange(null, 1);
    };

    const handleItemClick = async (itemId) => {
        // If changeBlocked is true, prevent user from selecting another item
        if (changeBlocked) {
            openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
            return;
        }

        // If changesToSave is true, prompt user to save changes
        if (changesToSave) {
            const result = await openConfirmationDialog('Unsaved Changes', 'You have unsaved changes. Do you want to continue?');
            if (!result) return;
        }
        setSelectedItemId((prevSelected) => (prevSelected === itemId ? null : itemId));

        // Find the item in imageIdList based on the itemId
        const selectedImage = imageIdList.find((image) => image.imageId === itemId);

        if (selectedImage) {
            // If the image is found, set the isCompleted state
            setIsCompleted(selectedImage.isCompleted === 'true');
        } else {
            console.warn('Image not found in list for itemId:', itemId);
        }
    };

    useEffect(() => {
        console.log(imageIdList);
    }, [imageIdList]);

    useEffect(() => {
        setSelectedImage(selectedItemId);
    }, [selectedItemId, setSelectedImage]); // eslint-disable-line

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}>

            {/* Project Selector */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel id="job-selector-label">Select Project</InputLabel>
                <Select
                    labelId="job-selector-label"
                    value={jobId? jobId : ''}
                    onChange={handleJobChange}
                    label="Select Job"
                >
                    {jobs?.map((job) => (
                        <MenuItem key={job.id} value={job.id}>
                            {job.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* List container with constrained height and scrollable overflow */}
            <Box sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 2, padding: 1, overflowY: 'auto', minHeight: 0 }}>
                <List>
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <ListItem
                                key={item.id}
                                selected={item.id === selectedItemId}
                                onClick={() => handleItemClick(item.id)}
                                sx={{
                                    cursor: 'pointer',
                                    backgroundColor: item.id === selectedItemId ? 'primary.light' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'primary.highlight',
                                        color: 'white',
                                    },
                                    transition: 'background-color 0.3s ease',
                                    borderRadius: '4px',
                                }}
                            >
                                {renderItem(item, index + 1 + offset)}
                            </ListItem>
                        ))
                    ) : (
                        <Typography sx={{ textAlign: 'center', padding: '16px' }}>No items to display</Typography>
                    )}
                </List>
            </Box>

            {/* Display total number of items */}
            <Typography sx={{ marginTop: '2px', marginBottom: 0, textAlign: 'right' }}>
                Total: {totalItems}
            </Typography>

            {/* Pagination Component */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                <Pagination count={Math.ceil(totalItems / limit)} page={page} onChange={handlePageChange} color="primary" />
            </Box>

        </Paper>
    );
};

export default ClientExplorerList;
