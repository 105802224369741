import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';
import { Paper, ListItemText, Typography, Box, Button } from '@mui/material';


import ImageJobsLists from '../../components/common/lists/ImageJobsLists';
import AnnotationPanel from '../../components/common/image/AnnotationPanel';

import UserProjectJobSelector from '../../components/common/selector/UserProjectJobSelector';


const Jobs = () => {
    // States
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);

    // Job essential information
    const [workerId, setWorkerId] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [jobId, setJobId] = useState(null);

    // Image information and changes
    const [changesToSave, setChangesToSave] = useState(false);
    const [changeBlocked, setChangeBlocked] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [enableValidation, setEnableValidation] = useState(false);

    const selectedImageId = selectedImage? selectedImage.id : null;
    useEffect(() => {
        console.log(selectedImage);
    }, [selectedImage]);

    return (
        // Grid layout of 4 by 4
        <Box display="grid" flexGrow={1} gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(15, 1fr)" gap={2} sx={{height: '100%', minHeight: 0}}>

            {/* Select the user, project and job */}
            <Box gridColumn="span 3" gridRow="span 3" bgcolor="lightblue" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <UserProjectJobSelector
                    setWorkerId={setWorkerId}
                    setProjectId={setProjectId}
                    setJobId={setJobId}
                    changesToSave={changesToSave}
                    changeBlocked={changeBlocked}
                />

            </Box>

            {/* Selected user information */}
            <Box gridColumn="span 3" gridRow="4 / span 12" bgcolor='lightblue' sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <ImageJobsLists
                    key = {workerId + projectId + jobId}
                    projectId={projectId}
                    workerId={workerId}
                    jobId={jobId}
                    setSelectedImage={setSelectedImage}
                    setEnableValidation={setEnableValidation}
                    changesToSave={changesToSave}
                    changeBlocked={changeBlocked}
                />

            </Box>

            {/* Annotation Panel */}
            <Box gridColumn="span 9" gridRow="span 15" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1, gap: 2}}>
                <AnnotationPanel
                    selectedImage={selectedImageId}
                    projectId={projectId}
                    setPendingChanges={setChangesToSave}
                    setChangeBlocked={setChangeBlocked}
                    enableValidation={enableValidation}
                />
            </Box>

        </Box>
    );
};

export default Jobs;
