import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, title, message }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="yesno-dialog-title"
            aria-describedby="yesno-dialog-description"
        >
            <DialogTitle id="yesno-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="yesno-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {/* No button */}
                <Button onClick={onClose} color="secondary">
                    No
                </Button>

                {/* Yes button */}
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
