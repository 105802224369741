import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText, Checkbox, Box, Typography } from "@mui/material";
import { useProgress } from "../../../hooks/useProgress";
import { useAppContext } from "../../../utils/ApplicationProvider";

const UpdateProgressDialog = ({ open, onClose, projectId, imageId, onSave }) => {
    const { openNotificationDialog } = useAppContext();
    const { progress, setProgress, submitProgress, isGettingProgress, isUploadingProgress } = useProgress(projectId, imageId, open);
    const [localProgress, setLocalProgress] = useState([]);

    // Initialize local progress state when progress is loaded
    useEffect(() => {
        if (progress && open) {
            setLocalProgress(progress); // Progress is an array of objects now
        }
    }, [progress, open]);

    // Handle checkbox toggle for each label
    const handleCheckboxToggle = (labelId) => {
        setLocalProgress((prevProgress) =>
            prevProgress.map((label) =>
                label.labelId === labelId ? { ...label, isAnnotated: !label.isAnnotated } : label
            )
        );
    };

    // Handle Save click
    const handleSave = async () => {
        const result = await submitProgress(localProgress);
        if (result.error) {
            openNotificationDialog('Error', 'Failed to save progress');
            return;
        }
        onSave();
        onClose();
    };

    // Handle Discard click
    const handleDiscard = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="label-state-dialog-title">
            <DialogTitle id="label-state-dialog-title">Labels Completed</DialogTitle>

            <DialogContent dividers>
                {isGettingProgress ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100px">
                        <Typography variant="body1">Loading...</Typography>
                    </Box>

                ) : (
                    <List>
                        {localProgress.map(({ labelId, name, isAnnotated }) => (
                            <ListItem key={labelId} button={true} onClick={() => handleCheckboxToggle(labelId)}> {/* Changed button to boolean */}
                                <Checkbox
                                    edge="start"
                                    checked={isAnnotated}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": `label-${labelId}` }}
                                />
                                    <ListItemText id={`label-${labelId}`} primary={name} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>

            <DialogActions>
                {/* Discard button */}
                <Button onClick={handleDiscard} color="secondary">
                    Discard
                </Button>

                {/* Save button */}
                <Button onClick={handleSave} color="primary" disabled={isUploadingProgress}>
                    {isUploadingProgress ? "Saving..." : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateProgressDialog;
