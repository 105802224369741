import { useState, useEffect } from 'react';
import { useGetAllUsersQuery } from '../api/userApi';

const useAllUsers = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the initial page of users
    const { data, error: fetchError, isLoading, refetch } = useGetAllUsersQuery({ limit: 100, offset: 0});

    // Handle the response data from the hook
    useEffect(() => {
        if (isLoading) {
            setLoading(true);
            setError(null);
        } else if (fetchError) {
            setLoading(false);
            setError(fetchError);
        } else if (data) {
            const { items, total } = data;
            setAllUsers(items);
            setTotalUsers(total);
            setLoading(false);
        }
    }, [data, fetchError, isLoading]);

    // Function to load all users
    const fetchAllUsers = async () => {
        let offset = 0;
        const allItems = [];

        // Fetch while more items exist
        while (offset < totalUsers) {
            const result = await refetch({ limit: 100, offset });
            if (result.error) {
                setError(result.error);
                break;
            }
            allItems.push(...result.data.items);
            offset += 100;
        }
        setAllUsers(allItems);
    };

    // Call fetchAllUsers when the totalUsers value updates
    useEffect(() => {
        if (totalUsers > 0) {
            fetchAllUsers();
        }
    }, [totalUsers]); // eslint-disable-line react-hooks/exhaustive-deps

    return { users: allUsers, totalUsers, loading, error };
};

export default useAllUsers;
