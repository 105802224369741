import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/label`,
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the label API
export const labelApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "labelApi",
    tagTypes: ["Label"],

    endpoints: (build) => ({

        // Get all labels with pagination support and projectId
        getAllLabels: build.query({
            query: ({ projectId, limit, offset }) => ({
                url: `/${projectId}?limit=${limit}&offset=${offset}`,
                method: "GET",
            }),
            providesTags: ["Label"],
        }),

        // Delete a label by id
        deleteLabel: build.mutation({
            query: (labelId) => ({
                url: `/${labelId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Label"],
        }),

        // Create a new label
        createLabel: build.mutation({
            query: (newLabel) => ({
                url: `/${newLabel.projectId}`,
                method: "POST",
                body: newLabel,
            }),
            invalidatesTags: ["Label"],
        }),

        // Edit a label
        editLabel: build.mutation({
            query: (editLabel) => ({
                url: `/${editLabel.labelId}`,
                method: "PUT",
                body: editLabel,
            }),
            invalidatesTags: ["Label"],
        }),

    }),
});

// Export hooks for using the label API
export const {
    useCreateLabelMutation,
    useGetAllLabelsQuery,
    useDeleteLabelMutation,
    useEditLabelMutation,
} = labelApi;