import React from 'react';
import { ToggleButton, Box } from '@mui/material';

import { getContrastColor } from '../../../../utils/colorUtils';

// Helper function to extract the base name (before the comma)
const getBaseName = (name) => {
    return name.includes(',') ? name.split(',')[0].trim() : name.trim();
};

// LabelToggle component
const LabelToggle = ({ labels, visibleLabels, handleToggleLabelVisibility }) => {
    // Group labels by their base name
    const groupedLabels = labels.reduce((acc, label) => {
        const baseName = getBaseName(label.name);
        if (!acc[baseName]) {
            acc[baseName] = [];
        }
        acc[baseName].push(label);
        return acc;
    }, {});

    return (
        <Box sx={{ marginTop: 2, padding: 1, backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {Object.keys(groupedLabels).map((baseName) => (
                    <ToggleButton
                        key={baseName}
                        value={baseName}
                        selected={groupedLabels[baseName].every(label => visibleLabels.includes(label.name))}
                        onClick={() => handleToggleLabelVisibility(baseName, groupedLabels[baseName])}
                        sx={{
                            height: 32,
                            borderColor: groupedLabels[baseName][0].color,
                            color: groupedLabels[baseName][0].color,
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: groupedLabels[baseName][0].color,
                                color: getContrastColor(groupedLabels[baseName][0].color),
                            },
                            '&.Mui-selected': {
                                backgroundColor: groupedLabels[baseName][0].color,
                                color: getContrastColor(groupedLabels[baseName][0].color),
                                '&:hover': {
                                    backgroundColor: groupedLabels[baseName][0].color,
                                },
                            },
                            borderWidth: 2,
                            textTransform: 'none',
                        }}
                    >
                        {baseName}
                    </ToggleButton>
                ))}
            </Box>
        </Box>
    );
};

export default LabelToggle;
