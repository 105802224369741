import { configureStore, isPlain } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import { authApi } from '../api/authApi';
import { userApi } from '../api/userApi';
import { projectApi } from '../api/projectApi';
import { labelApi } from '../api/labelApi';
import { imageApi } from '../api/imageApi';
import { progressApi } from '../api/progressApi';
import { annotationApi } from '../api/annotationApi';
import { jobApi } from '../api/jobApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [labelApi.reducerPath]: labelApi.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    [progressApi.reducerPath]: progressApi.reducer,
    [annotationApi.reducerPath]: annotationApi.reducer,
    [jobApi.reducerPath]: jobApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Custom isSerializable function to include Blobs
        isSerializable: (value) => isPlain(value) || value instanceof Blob || value instanceof FormData,
        // Ignore non-serializable values in meta.baseQueryMeta
        ignoredActionPaths: ['meta.baseQueryMeta'],
      },
    })
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(projectApi.middleware)
      .concat(labelApi.middleware)
      .concat(imageApi.middleware)
      .concat(progressApi.middleware)
      .concat(annotationApi.middleware)
      .concat(jobApi.middleware),
});
