import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem } from '@mui/material';
import { ChromePicker } from 'react-color'; // Importing ChromePicker from react-color

const AddLabelDialog = ({ open, onClose, onCreate, initialColor = '#ffffff' }) => {
    // Local state to manage form input
    const [name, setName] = useState('');
    const [color, setColor] = useState(initialColor); // Initial color for the color picker
    const [type, setType] = useState('Polygon');
    const [isNameValid, setIsNameValid] = useState(false);

    // Types for the dropdown
    const types = ['Polygon', 'Line', 'Box'];

    // Handle changes to the name field
    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
        setIsNameValid(value.length > 0); // Valid if the name has content
    };

    // Handle color change from the color picker (while dragging)
    const handleColorChange = (newColor) => {
        setColor(newColor.hex); // Update color as hex value
    };

    // Handle the creation of the label
    const handleCreate = () => {
        const newLabel = { name, color, type };
        onCreate(newLabel);
        onClose();

        // Clear the form
        setName('');
        setColor('#ffffff');
        setType('Polygon');
    };

    // Button should be enabled only if the name is valid
    const isFormValid = isNameValid;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="add-label-dialog-title"
            aria-describedby="add-label-dialog-description"
        >
            <DialogTitle id="add-label-dialog-title">Add New Label</DialogTitle>
            <DialogContent>
                {/* Name field */}
                <TextField
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={handleNameChange}
                    error={!isNameValid && name.length > 0}
                    helperText={!isNameValid && name.length > 0 ? "Name is required" : ""}
                />

                {/* Color picker */}
                <div style={{ margin: '20px 0' }}>
                    <ChromePicker color={color} onChange={handleColorChange} />
                </div>

                {/* Type selection */}
                <TextField
                    margin="dense"
                    label="Type"
                    select
                    fullWidth
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                >
                    {types.map((typeOption) => (
                        <MenuItem key={typeOption} value={typeOption}>
                            {typeOption}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                {/* Cancel button */}
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>

                {/* Create button, disabled if form is invalid */}
                <Button onClick={handleCreate} color="primary" autoFocus disabled={!isFormValid}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddLabelDialog;
