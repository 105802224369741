// React and MUI
import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';
import { Box, Paper, Typography, IconButton, ToggleButton, Tooltip, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText, Collapse, Menu } from '@mui/material';

// Icons
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CircleIcon from '@mui/icons-material/Circle';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CancelIcon from '@mui/icons-material/Cancel';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Konva
import Konva from 'konva';
import { Stage, Layer, Image as KonvaImage, Line, Rect, Circle } from 'react-konva';
import debounce from 'lodash.debounce';

// Hooks and utils
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { useAppContext } from '../../../utils/ApplicationProvider';
import useAllLabels from '../../../hooks/useAllLabels';
import { useDownloadImageMutation, useDownloadAnnotationMutation, useUploadAnnotationMutation } from '../../../api/imageApi';
import { hexToRGBA, getContrastColor } from '../../../utils/colorUtils';
import UpdateProgressDialog from '../dialogs/UpdateProgressDialog';
import LabelToggle from './utils/LabelToogle';
import LabelSelection from './utils/LabelSelection';
import AnnotationsPanel from './utils/AnnotationsPanel';
import ContrastBrightnessAdjuster from './utils/ContrastBrightnessAdjuster';


const AnnotationPanel = ({ projectId, selectedImage, setPendingChanges, setChangeBlocked, enableValidation=true, enableIsCompleted=false, isCompleted, handleIsCompleted }) => {
    // User and context
    const { user } = useSelector((state) => state.auth);
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();

    // References
    const imageRef = useRef(null);
    const stageRef = useRef(null);
    const containerRef = useRef(null);

    // States to handle image state
    const [image, setImage] = useState(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [brightness, setBrightness] = useState(0);
    const [contrast, setContrast] = useState(0);
    const [downloadImage] = useDownloadImageMutation();
    const [downloadAnnotation] = useDownloadAnnotationMutation();
    const [uploadAnnotation] = useUploadAnnotationMutation();
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [fetchAnnotations, setFetchAnnotations] = useState([]);
    const [progressDialogOpen, setProgressDialogOpen] = useState(false);

    // States to handle scaling and position
    const [scale, setScale] = useState(1);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });

    // Drawing mode state
    const [drawingMode, setDrawingMode] = useState('Polygon');

    // Drawing state
    const [isDrawing, setIsDrawing] = useState(false); // Tracks if drawing is active

    // Labels
    const { labels = [] } = useAllLabels(projectId);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [visibleLabels, setVisibleLabels] = useState([]);

    // Initialize selected label
    useEffect(() => {
        if (labels.length > 0 && !selectedLabel) {
            setSelectedLabel(null);
        }
        setVisibleLabels(labels.map((label) => label.name));
    }, [labels]); // eslint-disable-line react-hooks/exhaustive-deps

    // Pen size and vertex size
    const [penSize, setPenSize] = useState(2);
    const [vertexSize, setVertexSize] = useState(5);

    // Menu anchors for vertex and pen size
    const [vertexSizeAnchorEl, setVertexSizeAnchorEl] = useState(null);
    const [penSizeAnchorEl, setPenSizeAnchorEl] = useState(null);

    // Annotations
    const [annotations, setAnnotations] = useState([]);
    const [currentAnnotation, setCurrentAnnotation] = useState(null);

    // Current mouse position for preview
    const [currentMousePosition, setCurrentMousePosition] = useState(null);

    // Dynamic fill for Polygon
    const [dynamicFill, setDynamicFill] = useState(false);

    // Show/hide vertices
    const [showVertices, setShowVertices] = useState(false);

    // Editing state
    const [isEditing, setIsEditing] = useState(false);
    const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);

    // Vertex dragging state
    const [draggingVertex, setDraggingVertex] = useState(null);

    // State to control the visibility of the Annotations panel
    const [showAnnotationsPanel, setShowAnnotationsPanel] = useState(false);

    // State to control expanded labels in the Annotations panel
    const [expandedLabels, setExpandedLabels] = useState({});

    // Use effect to let the user know that something is not saved
    useEffect(() => {
        if(unsavedChanges) {
            setPendingChanges(true);
        } else {
            setPendingChanges(false);
        }
    }, [unsavedChanges, setPendingChanges]);

    // Use effect to block changes
    useEffect(() => {
        if(isEditing || isDrawing) {
            setChangeBlocked(true);
        } else {
            setChangeBlocked(false);
        }
    }, [isEditing, isDrawing, setChangeBlocked]);

    // Callback to update the stage size
    const updateStageSize = useCallback(() => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;

            // Update stage size only if it has changed
            setStageSize((prevSize) => {
                if (prevSize.width !== containerWidth || prevSize.height !== containerHeight) {
                    return { width: containerWidth, height: containerHeight };
                }
                return prevSize; // No change in size
            });
        }
    }, []);

    // Use a ref to debounce the updateStageSize function
    const resizeTimeout = useRef(null);

    // Initialize ResizeObserver to watch for size changes on the container
    useLayoutEffect(() => {
        const observer = new ResizeObserver(() => {
            if (resizeTimeout.current) {
                clearTimeout(resizeTimeout.current);
            }
            resizeTimeout.current = setTimeout(() => {
                updateStageSize();
            }, 200);
        });

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        // Clean up the observer on component unmount
        return () => {
            if (containerRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(containerRef.current);
            }
            observer.disconnect();
            if (resizeTimeout.current) {
                clearTimeout(resizeTimeout.current);
            }
        };
    }, [updateStageSize]);

    // Handle image scale and position after image loads
    useEffect(() => {
        if (image && imageSize.width && imageSize.height && stageSize.width && stageSize.height) {
            const { width, height } = imageSize;
            const stageRatio = stageSize.width / stageSize.height;
            const imageRatio = width / height;

            let newScale = 1;
            let newPosition = { x: 0, y: 0 };

            if (stageRatio > imageRatio) {
                newScale = stageSize.height / height;
                newPosition = { x: (stageSize.width - width * newScale) / 2, y: 0 };
            } else {
                newScale = stageSize.width / width;
                newPosition = { x: 0, y: (stageSize.height - height * newScale) / 2 };
            }

            setScale(newScale);
            setPosition(newPosition);
        }
    }, [image, imageSize, stageSize]);

    // Debounced function to update filter values without re-caching
    const applyFiltersDebounced = useRef(
        debounce((imageNode, brightness, contrast) => {
            if (imageNode) {
                imageNode.brightness(brightness);
                imageNode.contrast(contrast);
                imageNode.getLayer().batchDraw();
            }
        }, 200)
    ).current;

    // Effect to apply filters when brightness or contrast changes
    useEffect(() => {
        if (imageRef.current) {
            applyFiltersDebounced(imageRef.current, brightness, contrast);
        }
    }, [brightness, contrast]); // eslint-disable-line react-hooks/exhaustive-deps

    // Convert blob to Image and set width and height
    const loadImage = useCallback((blob) => {
        const img = new window.Image();
        const url = URL.createObjectURL(blob);
        img.src = url;
        img.onload = () => {
            setImageSize({ width: img.width, height: img.height });
            setImage(img);
            URL.revokeObjectURL(url);
        };
    }, []);

    // Effect to cache image and set filters only once when image loads
    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.cache();
            imageRef.current.filters([Konva.Filters.Brighten, Konva.Filters.Contrast]);
            imageRef.current.getLayer().batchDraw();
        }
    }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

    // Download the selected image
    useEffect(() => {
        const fetchImage = async () => {
            if (selectedImage) {
                try {
                    
                    const blob = await downloadImage(selectedImage).unwrap();
                    loadImage(blob);
                } catch (error) {
                    console.error('Error downloading image:', error);
                }
            }
        };

        fetchImage();
    }, [selectedImage, downloadImage, loadImage]);

    // Download the annotations for the selected image
    useEffect(() => {
        const fetchAnnotations = async () => {
            if (selectedImage) {
                try {

                    const result = await downloadAnnotation(selectedImage)
                    if (result.error) {
                        openNotificationDialog('Error', 'Error downloading previous annotations');
                        return;
                    }

                    // Adapt the annotation format to the expected format by the Konva components
                    const annotationFormated = result.data.map((ann) => {
                        // Check if the annotation is a Polygon
                        if (ann.type === 'Polygon') {
                            // If it does not have more than 2 points, discard it
                            if (ann.points.length < 6) return null;

                                // Get the label that matches the ann.label
                                const label = labels.find((l) => l.name === ann.label);
                                if (!label) return null;

                                // Prepare the annotation to be returned
                                const newAnnotation = {
                                    id: ann.id,
                                    label: label,
                                    penSize: ann.stroke,
                                    points: ann.points,
                                    type: 'Polygon',
                                    author: ann.author,
                                    createdAt: ann.createdAt,
                                };

                                return newAnnotation;
                        } else if (ann.type === 'Line') {
                            // If it does not have more than 1 point, discard it
                            if (ann.points.length < 4) return null;

                            // Get the label that matches the ann.label
                            const label = labels.find((l) => l.name === ann.label);
                            if (!label) return null;

                            // Prepare an empty object to return with the needed fields
                            const newAnnotation = {
                                id: ann.id,
                                label: label,
                                penSize: ann.stroke,
                                points: ann.points,
                                type: 'Line',
                                author: ann.author,
                                createdAt: ann.createdAt,
                            };

                            return newAnnotation;

                        } else if (ann.type === 'Box') {
                            // If the width or height is 0, discard it
                            if (ann.width === 0 || ann.height === 0) return null;

                            // Get the label that matches the ann.label
                            const label = labels.find((l) => l.name === ann.label);
                            if (!label) return null;

                            // Prepare an empty object to return with the needed fields
                            const newAnnotation = {
                                id: ann.id,
                                label: label,
                                penSize: ann.penSize,
                                height: ann.bbox[3] - ann.bbox[1],
                                width: ann.bbox[2] - ann.bbox[0],
                                x: ann.bbox[0],
                                y: ann.bbox[1],
                                type: 'Box',
                                author: ann.author,
                                createdAt: ann.createdAt,
                            }

                            return newAnnotation;
                        }
                        else {
                            return null;
                        }
                    }).filter((ann) => ann !== null);

                    setAnnotations(annotationFormated);
                    setUnsavedChanges(false);

                } catch (error) {
                    console.error('Error downloading annotations:', error);
                }

            }
        };

        fetchAnnotations();
    }, [selectedImage, downloadAnnotation, fetchAnnotations]) // eslint-disable-line react-hooks/exhaustive-deps


    // Handle zooming with the mouse wheel
    const handleWheel = (e) => {
        // if (isDrawing || isEditing) return; // Disable zooming when drawing or editing

        e.evt.preventDefault();
        const scaleBy = 1.05; // Zoom factor
        const stage = stageRef.current;
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();
        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;
        newScale = Math.max(0.1, Math.min(10, newScale));

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        setScale(newScale);
        setPosition(newPos);
    };

    // Handle panning (dragging)
    const handleDragEnd = (e) => {
        setPosition({ x: e.target.x(), y: e.target.y() });
    };

    // Helper function to get mouse position relative to the image
    const getRelativePointerPosition = () => {
        const stage = stageRef.current;
        const transform = stage.getAbsoluteTransform().copy();
        transform.invert();
        const pos = stage.getPointerPosition();
        return transform.point(pos);
    };

    const handleClick = (e) => {
        if (isEditing) return;
        if (!isDrawing) return;

        const point = getRelativePointerPosition();

        if (drawingMode === 'Polygon') {
        if (!currentAnnotation) {
            setCurrentAnnotation({
                id: uuid(),
                type: 'Polygon',
                points: [point.x, point.y],
                label: selectedLabel,
                penSize,
            });
        } else {
            setCurrentAnnotation((prev) => ({
                ...prev,
                points: [...prev.points, point.x, point.y],
            }));
        }
        } else if (drawingMode === 'Line') {
            if (!currentAnnotation) {
                setCurrentAnnotation({
                    id: uuid(),
                    type: 'Line',
                    points: [point.x, point.y],
                    label: selectedLabel,
                    penSize,
                    });
            } else {
                setCurrentAnnotation((prev) => ({
                    ...prev,
                    points: [...prev.points, point.x, point.y],
                }));
            }
        } else if (drawingMode === 'Box') {
            if (!currentAnnotation) {
                setCurrentAnnotation({
                    id: uuid(),
                    type: 'Box',
                    x: point.x,
                    y: point.y,
                    width: 0,
                    height: 0,
                    label: selectedLabel,
                    penSize,
                });
            } else {
              // Finish the box
              setAnnotations((prev) => [...prev, currentAnnotation]);
              setCurrentAnnotation(null);
              setIsDrawing(false);
              setUnsavedChanges(true);
            }
        }
    };

    const handleMouseDown = (e) => {
        if (isEditing && selectedAnnotationId) {
            // Handle vertex dragging
            const pos = getRelativePointerPosition();
            const clickedOnVertex = checkVertexHit(pos);
            if (clickedOnVertex) {
                setDraggingVertex(clickedOnVertex);
                e.evt.cancelBubble = true; // Prevent event from bubbling to the Stage
            }
            return;
        }

        if (!isDrawing) return;
    };

    const handleMouseMove = (e) => {
        const point = getRelativePointerPosition();

        if (isEditing && draggingVertex) {
            // Update the position of the dragged vertex
            const { annotationId, vertexIndex } = draggingVertex;
            setAnnotations((prevAnnotations) =>
                prevAnnotations.map((ann) => {
                    if (ann.id === annotationId) {
                        const newPoints = [...ann.points];
                        newPoints[vertexIndex] = point.x;
                        newPoints[vertexIndex + 1] = point.y;
                        return { ...ann, points: newPoints };
                    }
                    return ann;
                })
            );
            setUnsavedChanges(true);
            return;
        }

        if (!isDrawing) return;

        if ((drawingMode === 'Polygon' || drawingMode === 'Line') && currentAnnotation) {
            setCurrentMousePosition({ x: point.x, y: point.y });
        } else if (drawingMode === 'Box' && currentAnnotation) {
            setCurrentAnnotation((prev) => ({
                ...prev,
                width: point.x - prev.x,
                height: point.y - prev.y,
            }));
            setCurrentMousePosition(null);

        } else if (drawingMode === 'Box' && currentAnnotation) {
            setCurrentAnnotation((prev) => ({
                ...prev,
                width: point.x - prev.x,
                height: point.y - prev.y,
            }));
            setCurrentMousePosition(null);
        }
    };

    const handleMouseUp = () => {
        if (isEditing && draggingVertex) {
            setDraggingVertex(null);
            return;
        }

        if (!isDrawing) return;
    };

    // Function to check if a vertex was clicked
    const checkVertexHit = (pos) => {
        if (!isEditing || !selectedAnnotationId) return null;
        const ann = annotations.find((a) => a.id === selectedAnnotationId);
        if (!ann || !ann.points) return null;

        for (let i = 0; i < ann.points.length; i += 2) {
            const x = ann.points[i];
            const y = ann.points[i + 1];
            const dx = x - pos.x;
            const dy = y - pos.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance < vertexSize) {
                return { annotationId: ann.id, vertexIndex: i };
            }
        }
        return null;
    };

    // Handle label visibility toggle
    const handleToggleLabelVisibility = (baseName, associatedLabels) => {
        const associatedLabelNames = associatedLabels.map(label => label.name);

        // Check if all labels for this baseName are already visible
        const allVisible = associatedLabelNames.every(name => visibleLabels.includes(name));

        if (allVisible) {
            // If all are visible, remove them from the visibleLabels array
            setVisibleLabels(visibleLabels.filter(name => !associatedLabelNames.includes(name)));
        } else {
            // Otherwise, add all labels to the visibleLabels array
            setVisibleLabels([...visibleLabels, ...associatedLabelNames]);
        }
    };

    // Handle Start/Stop Drawing
    const handleStartStopDrawing = () => {
        // Check if there is a label selected
        if (!selectedLabel || !selectedImage) return;

        setUnsavedChanges(true);

        if (isDrawing) {
            // If currently drawing, stop drawing
            if (currentAnnotation) {
                setAnnotations((prev) => [...prev, currentAnnotation]);
                setCurrentAnnotation(null);
            }
            setCurrentMousePosition(null);
            setIsDrawing(false);
        } else {
            // Start drawing
            setIsDrawing(true);
            setIsEditing(false); // Stop editing if drawing starts
            setSelectedAnnotationId(null);
            setCurrentAnnotation(null);
            setCurrentMousePosition(null);
        }
    };

    // Handle editing an annotation
    const handleEditAnnotation = (annotationId) => {
        setIsEditing(true);
        setSelectedAnnotationId(annotationId);
        setIsDrawing(false);
        setCurrentAnnotation(null);
        setCurrentMousePosition(null);
    };

    // Handle stopping editing
    const handleStopEditing = () => {
        setIsEditing(false);
        setSelectedAnnotationId(null);
        setDraggingVertex(null);
    };

    // Handle annotation selection
    const handleSelectAnnotation = (annotationId) => {
        setSelectedAnnotationId(annotationId);
        setIsEditing(false);
        setCurrentAnnotation(null);
        setCurrentMousePosition(null);
    };

    // Handle deleting an annotation
    const handleDeleteAnnotation = (annotationId) => {
        setAnnotations((prev) => prev.filter((ann) => ann.id !== annotationId));
        if (selectedAnnotationId === annotationId) {
            setIsEditing(false);
            setSelectedAnnotationId(null);
        }
        setUnsavedChanges(true);
    };

    // Handle expanding/collapsing labels in the Annotations panel
    const handleToggleLabel = (labelName) => {
        setExpandedLabels((prevState) => ({
            ...prevState,
            [labelName]: !prevState[labelName],
        }));
    };

    // Clear selected annotation when annotations panel is hidden
    useEffect(() => {
        if (!showAnnotationsPanel) {
            setSelectedAnnotationId(null);
            setIsEditing(false);
        }
    }, [showAnnotationsPanel]);

    // Group annotations by label
    const annotationsByLabel = annotations.reduce((acc, ann) => {
        const labelName = ann.label.name;
        if (!acc[labelName]) {
            acc[labelName] = [];
        }
        acc[labelName].push(ann);
        return acc;
    }, {});

    // Save changes in the annotations
    const handleSaveChanges = async () => {
        if (!selectedImage) return;
        setIsUpdating(true);

        // Prepare the annotations to be saved
        const annotationsToSave = annotations.map((ann) => {
            // Check if the annotation is a Polygon
            if (ann.type === 'Polygon') {
                // If it does not have more than 2 points, discard it
                if (ann.points.length < 6) return null;

                    // Round the points
                    const points = ann.points.map((p) => Math.round(p))

                    // Compute the widht and height of the annotation
                    const x = Math.min(...points.filter((_, i) => i % 2 === 0));
                    const y = Math.min(...points.filter((_, i) => i % 2 === 1));
                    const width = Math.max(...points.filter((_, i) => i % 2 === 0)) - x;
                    const height = Math.max(...points.filter((_, i) => i % 2 === 1)) - y;

                    // Compute the bbox in the format [xmin, ymin, xmax, ymax]
                    const bbox = [x, y, x + width, y + height];

                    // Prepare an empty object to return with the needed fields
                    const newAnnotation = {
                        id: ann.id,
                        label: ann.label.name,
                        stroke: ann.penSize,
                        points: points,
                        type: 'Polygon',
                        author: ann.author ? ann.author : user.email,
                        width: width,
                        height: height,
                        bbox: bbox,
                        createdAt: ann.createdAt ? ann.createdAt : new Date().toISOString(),
                    };

                    return newAnnotation;
            } else if (ann.type === 'Line') {
                // If it does not have more than 1 point, discard it
                if (ann.points.length < 4) return null;

                // Round the points
                const points = ann.points.map((p) => Math.round(p));

                // Prepare an empty object to return with the needed fields
                const newAnnotation = {
                    id: ann.id,
                    label: ann.label.name,
                    stroke: ann.penSize,
                    points: points,
                    type: 'Line',
                    author: ann.author ? ann.author : user.email,
                    createdAt: ann.createdAt ? ann.createdAt : new Date().toISOString(),
                };

                return newAnnotation;

            } else if (ann.type === 'Box') {
                // If the width or height is 0, discard it
                if (ann.width === 0 || ann.height === 0) return null;

                // Round the points
                const x = Math.round(ann.x);
                const y = Math.round(ann.y);
                const width = Math.round(ann.width);
                const height = Math.round(ann.height);

                // Compute the bbox in the format [xmin, ymin, xmax, ymax]
                const bbox = [x, y, x + width, y + height];

                // Prepare an empty object to return with the needed fields
                const newAnnotation = {
                    id: ann.id,
                    label: ann.label.name,
                    stroke: ann.penSize,
                    bbox: bbox,
                    type: 'Box',
                    author: ann.author ? ann.author : user.email,
                    createdAt: ann.createdAt ? ann.createdAt : new Date().toISOString(),
                }

                return newAnnotation;
            }
            else {
                return null;
            }
        }).filter((ann) => ann !== null);

        // Prepare the formData and append the JSON string as a form field
        const formData = new FormData();
        formData.append('annotations', JSON.stringify(annotationsToSave));

        // Upload the annotations
        try {
            const result = await uploadAnnotation({ imageId: selectedImage, formData });

            if (result.error) {
                openNotificationDialog('Error', 'Error saving the annotations');
                setIsUpdating(false);
            } else {
                // openNotificationDialog('Success', 'Annotations saved successfully');
                setUnsavedChanges(false);
                setIsUpdating(false);
            }
        } catch (error) {
            openNotificationDialog('Error', 'Error saving the annotations');
            setIsUpdating(false);
        }
    };

    // Update progress
    const handleUpdateProgress = () => {
        if (!selectedImage) return;
        setProgressDialogOpen(true);
    };

    // Discard changes in the annotations
    const handleDiscardChanges = async () => {
        if (!selectedImage) return;

        // Show a confirmation dialog before discarding changes
        const result = await openConfirmationDialog('Discard Changes', 'Are you sure you want to discard the changes?');
        if (!result) return;

        // Remove the modifications and load the original annotations
        setUnsavedChanges(false);
        setFetchAnnotations(prev => !prev);
    };

    const handleProgressDialogClose = () => {
        setProgressDialogOpen(false);
    };

    const handleProgressDialogSave = () => {
        setProgressDialogOpen(false);
    };

    const handleVariantChange = (annotationId, newVariant, baseName) => {
        setAnnotations(prevAnnotations =>
            prevAnnotations.map(ann =>
                ann.id === annotationId
                    ? {
                        ...ann,
                        label: {
                            ...ann.label,   // Copy other properties in `label`
                            name: `${baseName},${newVariant}`  // Update the `name` field in `label`
                        }
                    }
                    : ann
            )
        );
    };

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', minHeight: 0, padding: 2 }}>
            {/* Left Panel (Tools and Canvas) */}
            <Box sx={{ display: 'flex', flexDirection: 'column', width: showAnnotationsPanel ? 'calc(100% - 300px)' : '100%', transition: 'width 0.5s'}}>

                {/* Toolbar */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1, bgcolor: '#f5f5f5', borderRadius: '8px', mb: 2, userSelect: 'none'}} draggable={false}>

                    {/* Left Section. Save the changes */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                        {/* Save Button */}
                        <Tooltip title={'Save Changes'}>
                            <ToggleButton
                                value={unsavedChanges}
                                selected={false}
                                onClick={handleSaveChanges}
                                sx={{
                                    backgroundColor: unsavedChanges ? '#ff0000' : '#115293',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: unsavedChanges ? '#ff0000' : '#115293',
                                    },
                                    border: '1px solid',
                                    borderColor: unsavedChanges ? '#ff0000' : '#115293',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                {isUpdating ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <SaveAsIcon />}
                            </ToggleButton>
                        </Tooltip>

                        {/* Cancel Button */}
                        <Tooltip title={'Discard Changes'}>
                            <ToggleButton
                                value={unsavedChanges}
                                selected={false}
                                onClick={handleDiscardChanges}
                                sx={{
                                    backgroundColor: '#c51162',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#c51162',
                                    },
                                    border: '1px solid',
                                    borderColor: '#c51162',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <CancelIcon aria-hidden={false} />
                            </ToggleButton>
                        </Tooltip>

                        {/* Update progress */}
                        {enableValidation === true && (
                            <Tooltip title={'Update progress'}>
                                <ToggleButton
                                    value={unsavedChanges}
                                    selected={false}
                                    onClick={handleUpdateProgress}
                                    sx={{
                                        backgroundColor: '#115293',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#115293',
                                        },
                                        border: '1px solid',
                                        borderColor: '#115293',
                                        borderRadius: '8px',
                                        padding: '8px',
                                    }}
                                >
                                    <AssessmentIcon />
                                </ToggleButton>
                            </Tooltip>
                        )}

                        {/* Update progress */}
                        {enableIsCompleted === true && (
                            <Tooltip title={isCompleted ? 'Mark as Not Completed' : 'Mark as Completed'}>
                                <ToggleButton
                                    value={isCompleted}
                                    selected={isCompleted}
                                    onClick={handleIsCompleted}
                                    sx={{
                                        backgroundColor: isCompleted ? '#4caf50' : '#f44336',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: isCompleted ? '#4caf50' : '#d32f2f',
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: isCompleted ? '#4caf50' : '#d32f2f',
                                            color: '#fff',
                                        },
                                        border: '1px solid',
                                        borderColor: isCompleted ? '#4caf50' : '#f44336',
                                        borderRadius: '8px',
                                        padding: '8px',
                                    }}
                                >
                                    {isCompleted ? <CheckCircleIcon /> : <CheckCircleIcon />}
                                </ToggleButton>
                            </Tooltip>
                        )}
                    </Box>

                    {/* Right Section. Options */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                        {/* Label Selection */}
                        <LabelSelection
                            labels = {labels}
                            isDrawing = {isDrawing}
                            isEditing = {isEditing}
                            setSelectedLabel = {setSelectedLabel}
                            setDrawingMode = {setDrawingMode}
                        />

                        {/* Start/Stop Drawing Button */}
                        <Tooltip title={isDrawing ? 'Stop Drawing' : 'Start Drawing'}>
                            <ToggleButton
                                value={isDrawing}
                                selected={isDrawing}
                                onChange={handleStartStopDrawing}
                                disabled={isEditing}
                                sx={{
                                    backgroundColor: isDrawing ? '#f50057' : '#115293',
                                    color: '#fff',
                                    '&.Mui-selected': {
                                        backgroundColor: '#c51162',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: isDrawing ? '#c51162' : '#115293',
                                        },
                                    },
                                    '&:hover': {
                                        backgroundColor: isDrawing ? '#c51162' : '#115293',
                                    },
                                    border: '1px solid',
                                    borderColor: isDrawing ? '#c51162' : '#115293',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <CreateIcon />
                            </ToggleButton>
                        </Tooltip>

                        {/* Pen Size */}
                        <Tooltip title="Pen Size">
                            <ToggleButton
                                value={penSize}
                                selected={Boolean(penSizeAnchorEl)}
                                onClick={(e) => setPenSizeAnchorEl(e.currentTarget)}
                                disabled={isDrawing || isEditing}
                                sx={{
                                    backgroundColor: '#115293',
                                    color: '#fff',
                                    '&.Mui-selected': {
                                        backgroundColor: '#115293',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#115293',
                                    },
                                    border: '1px solid',
                                    borderColor: '#115293',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <LineWeightIcon />
                            </ToggleButton>
                        </Tooltip>

                        {/* Pen Size Menu */}
                        <Menu anchorEl={penSizeAnchorEl} open={Boolean(penSizeAnchorEl)} onClose={() => setPenSizeAnchorEl(null)}>
                            {[...Array(10)].map((_, index) => (
                                    <MenuItem
                                        key={index + 1}
                                        selected={penSize === index + 1}
                                        onClick={() => {
                                            setPenSize(index + 1);
                                            setPenSizeAnchorEl(null);
                                        }}
                                    >
                                        {index + 1}
                                </MenuItem>
                            ))}
                        </Menu>

                        {/* Vertex Size */}
                        <Tooltip title="Vertex Size">
                            <ToggleButton
                                value={vertexSize}
                                selected={Boolean(vertexSizeAnchorEl)}
                                onClick={(e) => setVertexSizeAnchorEl(e.currentTarget)}
                                disabled={isDrawing || isEditing}
                                sx={{
                                    backgroundColor: '#115293',
                                    color: '#fff',
                                    '&.Mui-selected': {
                                        backgroundColor: '#115293',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#115293',
                                    },
                                    border: '1px solid',
                                    borderColor: '#115293',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <CircleIcon />
                            </ToggleButton>
                        </Tooltip>

                        {/* Vertex Size Menu */}
                        <Menu anchorEl={vertexSizeAnchorEl} open={Boolean(vertexSizeAnchorEl)} onClose={() => setVertexSizeAnchorEl(null)}>
                            {[...Array(10)].map((_, index) => (
                                <MenuItem
                                    key={index + 1}
                                    selected={vertexSize === index + 1}
                                    onClick={() => {
                                        setVertexSize(index + 1);
                                        setVertexSizeAnchorEl(null);
                                    }}
                                >
                                {index + 1}
                                </MenuItem>
                            ))}
                        </Menu>

                        <ContrastBrightnessAdjuster
                            contrast={contrast}
                            setContrast={setContrast}
                            brightness={brightness}
                            setBrightness={setBrightness}
                            isDrawing={isDrawing}
                            isEditing={isEditing}
                        />

                        {/* Dynamic Fill Toggle */}
                        <Tooltip title="Dynamic Fill">
                            <ToggleButton
                                value={dynamicFill}
                                selected={dynamicFill}
                                onChange={() => setDynamicFill(!dynamicFill)}
                                sx={{
                                    backgroundColor: dynamicFill ? '#115293' : '#d3e0ee',
                                    color: '#fff',
                                    '&.Mui-selected': {
                                        backgroundColor: '#115293',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: dynamicFill ? '#115293' : '#d3e0ee',
                                    },
                                    border: '1px solid',
                                    borderColor: dynamicFill ? '#115293' : '#d3e0ee',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <FormatColorFillIcon />
                            </ToggleButton>
                        </Tooltip>

                        {/* Show Vertices Toggle */}
                        <Tooltip title="Show Vertices">
                            <ToggleButton
                                value={showVertices}
                                selected={showVertices}
                                onChange={() => setShowVertices(!showVertices)}
                                sx={{
                                    backgroundColor: showVertices ? '#115293' : '#d3e0ee',
                                    color: '#fff',
                                    '&.Mui-selected': {
                                        backgroundColor: '#115293',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: showVertices ? '#115293' : '#d3e0ee',
                                    },
                                    border: '1px solid',
                                    borderColor: showVertices ? '#115293' : '#d3e0ee',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                <ScatterPlotIcon />
                            </ToggleButton>
                        </Tooltip>

                        {/* Toggle Annotations Panel */}
                        <Tooltip title={showAnnotationsPanel ? 'Hide Annotations' : 'Show Annotations'}>
                            <ToggleButton
                                value={showAnnotationsPanel}
                                selected={showAnnotationsPanel}
                                onChange={() => setShowAnnotationsPanel(!showAnnotationsPanel)}
                                disabled={isDrawing || isEditing}
                                sx={{
                                    backgroundColor:'#115293',
                                    color: '#fff',
                                    '&.Mui-selected': {
                                        backgroundColor: '#115293',
                                        color: '#fff',
                                    },
                                    '&:hover': {
                                        backgroundColor: '#115293',
                                    },
                                    border: '1px solid',
                                    borderColor: '#115293',
                                    borderRadius: '8px',
                                    padding: '8px',
                                }}
                            >
                                {showAnnotationsPanel ? <MenuOpenIcon /> : <MenuIcon />}
                            </ToggleButton>
                        </Tooltip>
                    </Box>
                </Box>

                {/* Canvas Area */}
                <Box ref={containerRef} sx={{ flexGrow: 1, bgcolor: '#cedfe5', borderRadius: '8px', overflow: 'hidden', border: '1px solid #cccccc' }}>
                    <Stage
                        ref={stageRef}
                        width={stageSize.width}
                        height={stageSize.height}
                        scale={{ x: scale, y: scale }}
                        x={position.x}
                        y={position.y}
                        onClick={handleClick}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onWheel={handleWheel}
                        draggable={!isDrawing && !isEditing}
                        onDragEnd={handleDragEnd}
                        style={{ touchAction: 'none' }}
                    >
                        {/* Image Layer */}
                        <Layer>
                            {image && (
                                <KonvaImage
                                    image={image}
                                    ref={imageRef}
                                    width={imageSize.width}
                                    height={imageSize.height}
                                    // filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
                                    // brightness={brightness}
                                    // contrast={contrast}
                                />
                            )}
                        </Layer>

                        {/* Annotation Layer */}
                        <Layer>

                            {/* Render the annotations that have been made */}
                            {annotations
                                .filter((annotation) => visibleLabels.includes(annotation.label.name))
                                .map((annotation) => {
                                    const isSelected = annotation.id === selectedAnnotationId;
                                    const strokeWidth = isSelected ? annotation.penSize + 2 : annotation.penSize;
                                    const color = isSelected ? 'yellow' : annotation.label.color;

                                    // Polygon Annotation
                                    if (annotation.type === 'Polygon') {
                                        return (
                                            <React.Fragment key={annotation.id}>
                                                {/* Render the polygon */}
                                                <Line
                                                    points={annotation.points}
                                                    stroke={color}
                                                    strokeWidth={strokeWidth}
                                                    closed
                                                    fill={hexToRGBA(annotation.label.color, 0.3)}
                                                />

                                                {/* Render circles at vertices */}
                                                {showVertices &&
                                                    annotation.points.map((_, idx) => {
                                                        if (idx % 2 === 0) {
                                                            const x = annotation.points[idx];
                                                            const y = annotation.points[idx + 1];
                                                            return (
                                                                <Circle
                                                                    key={`${annotation.id}-${idx}`}
                                                                    x={x}
                                                                    y={y}
                                                                    radius={vertexSize}
                                                                    fill={annotation.label.color}
                                                                    draggable={isEditing && isSelected}
                                                                    onDragMove={(e) => {
                                                                        if (!isEditing || !isSelected) return;
                                                                        const newX = e.target.x();
                                                                        const newY = e.target.y();
                                                                        setAnnotations((prevAnnotations) =>
                                                                            prevAnnotations.map((ann) => {
                                                                                if (ann.id === annotation.id) {
                                                                                    const newPoints = [...ann.points];
                                                                                    newPoints[idx] = newX;
                                                                                    newPoints[idx + 1] = newY;
                                                                                    return { ...ann, points: newPoints };
                                                                                }
                                                                                return ann;
                                                                            })
                                                                        );
                                                                        setUnsavedChanges(true);
                                                                        e.cancelBubble = true; // Prevent event from bubbling to the Stage
                                                                    }}
                                                                    onDragEnd={(e) => {
                                                                        e.cancelBubble = true;
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.scale({ x: 1.3, y: 1.3 });
                                                                        e.target.draw();
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.scale({ x: 1, y: 1 });
                                                                        e.target.draw();
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </React.Fragment>
                                        );

                                    // Line Annotation
                                    } else if (annotation.type === 'Line') {
                                        return (
                                            <React.Fragment key={annotation.id}>
                                                {/* Render the line */}
                                                <Line
                                                    points={annotation.points}
                                                    stroke={color}
                                                    strokeWidth={strokeWidth}
                                                />

                                                {/* Render circles at points */}
                                                {showVertices &&
                                                    annotation.points.map((_, idx) => {
                                                        if (idx % 2 === 0) {
                                                            const x = annotation.points[idx];
                                                            const y = annotation.points[idx + 1];
                                                            return (
                                                                <Circle
                                                                    key={`${annotation.id}-${idx}`}
                                                                    x={x}
                                                                    y={y}
                                                                    radius={vertexSize}
                                                                    fill={annotation.label.color}
                                                                    draggable={isEditing && isSelected}
                                                                    onDragMove={(e) => {
                                                                        if (!isEditing || !isSelected) return;
                                                                        const newX = e.target.x();
                                                                        const newY = e.target.y();
                                                                        setAnnotations((prevAnnotations) =>
                                                                            prevAnnotations.map((ann) => {
                                                                                if (ann.id === annotation.id) {
                                                                                    const newPoints = [...ann.points];
                                                                                    newPoints[idx] = newX;
                                                                                    newPoints[idx + 1] = newY;
                                                                                    return { ...ann, points: newPoints };
                                                                                }
                                                                                return ann;
                                                                            })
                                                                        );
                                                                        setUnsavedChanges(true);
                                                                        e.cancelBubble = true; // Prevent event from bubbling to the Stage
                                                                    }}
                                                                    onDragEnd={(e) => {
                                                                        e.cancelBubble = true;
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        e.target.scale({ x: 1.2, y: 1.2 });
                                                                        e.target.draw();
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.target.scale({ x: 1, y: 1 });
                                                                        e.target.draw();
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </React.Fragment>
                                        );

                                    // Box Annotation
                                    } else if (annotation.type === 'Box') {
                                        return (
                                            // Render the box
                                            <Rect
                                                key={annotation.id}
                                                x={annotation.x}
                                                y={annotation.y}
                                                width={annotation.width}
                                                height={annotation.height}
                                                stroke={color}
                                                strokeWidth={strokeWidth}
                                                draggable={isEditing && isSelected}
                                                onDragMove={(e) => {
                                                    if (!isEditing || !isSelected) return;
                                                    const newX = e.target.x();
                                                    const newY = e.target.y();
                                                    setAnnotations((prevAnnotations) =>
                                                        prevAnnotations.map((ann) => {
                                                            if (ann.id === annotation.id) {
                                                                return { ...ann, x: newX, y: newY };
                                                            }
                                                            return ann;
                                                        })
                                                    );
                                                    setUnsavedChanges(true);
                                                    e.cancelBubble = true; // Prevent event from bubbling to the Stage
                                                }}
                                                onDragEnd={(e) => {
                                                    e.cancelBubble = true;
                                                }}
                                            />
                                        );
                                    }
                                        return null;
                                })
                            }

                            {/* Render current annotation being drawn */}
                            {currentAnnotation && (
                                <React.Fragment>

                                    {/* Current Polygon Annotation */}
                                    {drawingMode === 'Polygon' && (
                                        <>
                                            <Line
                                                points={
                                                    currentMousePosition 
                                                    ? [...currentAnnotation.points, currentMousePosition.x, currentMousePosition.y]
                                                    : currentAnnotation.points
                                                }
                                                stroke={currentAnnotation.label.color}
                                                strokeWidth={currentAnnotation.penSize}
                                                closed={dynamicFill}
                                                fill={
                                                    dynamicFill && currentAnnotation.points.length >= 6
                                                    ? hexToRGBA(currentAnnotation.label.color, 0.3)
                                                    : undefined
                                                }
                                                lineCap="round"
                                                lineJoin="round"
                                            />

                                            {/* Render circles at vertices */}
                                            {showVertices &&
                                                currentAnnotation.points.map((_, idx) => {
                                                    if (idx % 2 === 0) {
                                                        const x = currentAnnotation.points[idx];
                                                        const y = currentAnnotation.points[idx + 1];
                                                        return (
                                                            <Circle key={idx} x={x} y={y} radius={vertexSize} fill={currentAnnotation.label.color} />
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </>
                                    )}

                                    {/* Current Line Annotation */}
                                    {drawingMode === 'Line' && (
                                        <>
                                            <Line
                                                points={
                                                    currentMousePosition
                                                    ? [...currentAnnotation.points, currentMousePosition.x, currentMousePosition.y]
                                                    : currentAnnotation.points
                                                }
                                                stroke={currentAnnotation.label.color}
                                                strokeWidth={currentAnnotation.penSize}
                                                lineCap="round"
                                                lineJoin="round"
                                            />

                                            {/* Render circles at points */}
                                            {showVertices &&
                                                currentAnnotation.points.map((_, idx) => {
                                                    if (idx % 2 === 0) {
                                                        const x = currentAnnotation.points[idx];
                                                        const y = currentAnnotation.points[idx + 1];
                                                        return (
                                                            <Circle key={idx} x={x} y={y} radius={vertexSize} fill={currentAnnotation.label.color}/>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </>
                                    )}

                                    {/* Current Box Annotation */}
                                    {drawingMode === 'Box' && (
                                        <Rect
                                            x={currentAnnotation.x}
                                            y={currentAnnotation.y}
                                            width={currentAnnotation.width}
                                            height={currentAnnotation.height}
                                            stroke={currentAnnotation.label.color}
                                            strokeWidth={currentAnnotation.penSize}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </Layer>
                    </Stage>
                </Box>


                {/* Label Visibility Toggle */}
                <LabelToggle labels={labels} visibleLabels={visibleLabels} handleToggleLabelVisibility={handleToggleLabelVisibility} />

            </Box>

            {/* Right Panel (Annotations List) */}
            <AnnotationsPanel
                showAnnotationsPanel={showAnnotationsPanel}
                annotationsByLabel={annotationsByLabel}
                expandedLabels={expandedLabels}
                handleToggleLabel={handleToggleLabel}
                selectedAnnotationId={selectedAnnotationId}
                handleSelectAnnotation={handleSelectAnnotation}
                handleEditAnnotation={handleEditAnnotation}
                handleDeleteAnnotation={handleDeleteAnnotation}
                handleVariantChange={handleVariantChange}
                isEditing={isEditing}
                handleStopEditing={handleStopEditing}
            />

            {/* Update Progress Dialog */}
            <UpdateProgressDialog
                open={progressDialogOpen}
                onClose={handleProgressDialogClose}
                labels={labels}
                projectId={projectId ? projectId : ''}
                imageId={selectedImage ? selectedImage : ''}
                onSave={handleProgressDialogSave}
            />

        </Paper>
    );
};

export default AnnotationPanel;
