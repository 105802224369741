import React, { createContext, useContext, useState, useMemo } from 'react';
import NotificationDialog from '../components/common/dialogs/NotificationDialog';
import ConfirmationDialog from '../components/common/dialogs/ConfirmationDialog'; // Assuming this is the correct path

// Create a new context for the Application
const ApplicationContext = createContext();

// Export the custom hook for using the Application Context
export const useAppContext = () => useContext(ApplicationContext);

// Application provider
export const ApplicationProvider = ({ children }) => {
    // State to notify when the dialog is toggled
    const [sidebarToggle, setSidebarToggle] = useState(false);

    // Notification dialog
    const [notificationDialogState, setNotificationDialogState] = useState({
        open: false,
        title: '',
        message: '',
        onClose: null,
    });

    // Confirmation dialog state
    const [confirmationDialogState, setConfirmationDialogState] = useState({
        open: false,
        title: '',
        message: '',
        onConfirm: null,
        onClose: null,
    });

    // Notification dialog function with Promise
    const openNotificationDialog = (title, message) => {
        return new Promise((resolve) => {
            // Set dialog state to open and pass the resolve function to handle the close
            setNotificationDialogState({
                open: true,
                title,
                message,
                onClose: () => {
                    setNotificationDialogState({ ...notificationDialogState, open: false });
                    resolve();
                },
            });
        });
    };

    // Confirmation dialog function with Promise (resolves true for Yes, false for No)
    const openConfirmationDialog = (title, message) => {
        return new Promise((resolve) => {
            // Set the dialog to open and handle both Yes and No buttons
            setConfirmationDialogState({
                open: true,
                title,
                message,
                onConfirm: () => {
                    setConfirmationDialogState({ ...confirmationDialogState, open: false });
                    resolve(true); // Resolve with true when "Yes" is clicked
                },
                onClose: () => {
                    setConfirmationDialogState({ ...confirmationDialogState, open: false });
                    resolve(false); // Resolve with false when "No" is clicked
                },
            });
        });
    };

    // Memoized context value
    const value = useMemo(() => ({
        openNotificationDialog,
        openConfirmationDialog,
        sidebarToggle,
        setSidebarToggle
    }), [notificationDialogState, confirmationDialogState, sidebarToggle]);

    return (
        <ApplicationContext.Provider value={value}>
            {children}

            {/* Global NotificationDialog */}
            <NotificationDialog
                open={notificationDialogState.open}
                onClose={notificationDialogState.onClose}
                title={notificationDialogState.title}
                message={notificationDialogState.message}
            />

            {/* Global ConfirmationDialog */}
            <ConfirmationDialog
                open={confirmationDialogState.open}
                onConfirm={confirmationDialogState.onConfirm}
                onClose={confirmationDialogState.onClose}
                title={confirmationDialogState.title}
                message={confirmationDialogState.message}
            />
        </ApplicationContext.Provider>
    );
};
