import { useState, useEffect } from 'react';
import { useGetAllProjectsQuery } from '../api/projectApi';

const useAllProjects = () => {
    const [allProjects, setAllProjects] = useState([]);
    const [totalProjects, setTotalProjects] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the initial page of projects
    const { data, error: fetchError, isLoading, refetch } = useGetAllProjectsQuery({ limit: 100, offset: 0});

    // Handle the response data from the hook
    useEffect(() => {
        if (isLoading) {
            setLoading(true);
            setError(null);
        } else if (fetchError) {
            setLoading(false);
            setError(fetchError);
        } else if (data) {
            const { items, total } = data;
            setAllProjects(items);
            setTotalProjects(total);
            setLoading(false);
        }
    }, [data, fetchError, isLoading]);

    // Function to load all projects
    const fetchAllProjects = async () => {
        let offset = 0;
        const allItems = [];

        // Fetch while more items exist
        while (offset < totalProjects) {
            const result = await refetch({ limit: 100, offset });
            if (result.error) {
                setError(result.error);
                break;
            }
            allItems.push(...result.data.items);
            offset += 100;
        }
        setAllProjects(allItems);
    };

    // Call fetchAllProjects when the totalProjects value updates
    useEffect(() => {
        if (totalProjects > 0) {
            fetchAllProjects();
        }
    }, [totalProjects]);

    return { projects: allProjects, totalProjects, loading, error };
};

export default useAllProjects;
