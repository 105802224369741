import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useGetAllUsersQuery, useDeleteUserMutation, useCreateUserMutation } from '../../api/userApi';
import { useGetAllProjectsQuery, useDeleteProjectMutation, useCreateProjectMutation } from '../../api/projectApi';
import { useGetAllLabelsQuery, useDeleteLabelMutation, useCreateLabelMutation, useEditLabelMutation } from '../../api/labelApi';
import { useDeleteImageMutation, useEditImageMutation, useGetAllImagesQuery, useCreateImageMutation } from '../../api/imageApi';

import AdminExplorerList from '../../components/common/lists/AdminExplorerList';
import AnnotationPanel from '../../components/common/image/AnnotationPanel';
import ImageMetaData from '../../components/common/image/ImageMetaData';

const Annotation = () => {

    // Project and Images
    const [projectId, setProjectId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFullImage, setSelectedFullImage] = useState(null);
    const [changesToSave, setChangesToSave] = useState(false);
    const [changeBlocked, setChangeBlocked] = useState(false);

    return (
        // Grid layout of 4 by 4
        <Box display="grid" flexGrow={1} gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(12, 1fr)" gap={2} sx={{ height: '100%', minHeight: 0 }}>

            {/* Image Selector */}
            <Box gridColumn="span 3" gridRow="span 9" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <AdminExplorerList
                    setProjectId={setProjectId}
                    setSelectedImage={setSelectedImage}
                    changesToSave={changesToSave}
                    changeBlocked={changeBlocked}
                    setSelectedFullImage={setSelectedFullImage}
                />
            </Box>

            {/* Image Metadate */}
            <Box gridColumn="span 3" gridRow="10 / span 3" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <ImageMetaData
                    selectedImage={selectedFullImage? selectedFullImage : null}
                />
            </Box>

            {/* Example: Another box that spans 3 columns */}
            <Box gridColumn="span 9" gridRow="span 12" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <AnnotationPanel
                    selectedImage={selectedImage? selectedImage : null}
                    projectId={projectId}
                    setPendingChanges={setChangesToSave}
                    setChangeBlocked={setChangeBlocked}
                />
            </Box>

        </Box>
    );
};

export default Annotation;
