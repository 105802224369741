// constants.js
export const tables = ['Image', 'Progress', 'Annotation'];

export const fields = {
  Image: [
    { label: 'Quality', value: 'quality', type: 'number' },
    { label: 'Is Train', value: 'isTrain', type: 'boolean' },
    { label: 'Is Valid', value: 'isValid', type: 'boolean' },
    { label: 'Name', value: 'name', type: 'string' },
    { label: 'Tag', value: 'tag', type: 'string' },
  ],
  Progress: [
    { label: 'Is Annotated', value: 'isAnnotated', type: 'boolean' },
  ],
  Annotation: [
    { label: 'Count', value: 'count', type: 'number' },
  ],
};

export const operators = {
  number: [
    { label: 'Equal to', value: '==' },
    { label: 'Not equal to', value: '!=' },
    { label: 'Greater than', value: '>' },
    { label: 'Greater than or equal to', value: '>=' },
    { label: 'Less than', value: '<' },
    { label: 'Less than or equal to', value: '<=' },
  ],
  string: [
    { label: 'Contains', value: 'contains' },
    { label: 'Equal to', value: '==' },
    { label: 'Not equal to', value: '!=' },
  ],
  boolean: [
    { label: 'Is', value: '==' },
    { label: 'Is not', value: '!=' },
  ],
};
