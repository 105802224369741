import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem } from '@mui/material';

const EditJobDialog = ({ open, onClose, onEdit, creatorId, workerId, projectId, initialData }) => {

    // Local state to manage form input with initial values passed in
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('Open'); // Set the default value to "Open"

    // Status options (allow Open and Close now)
    const statusOptions = ['Open', 'Close'];

    // Effect to pre-populate fields when initialData changes
    useEffect(() => {
        if (initialData) {
            setName(initialData.name || '');
            setDescription(initialData.description || '');
            setStatus(initialData.status || 'Open');
        }
    }, [initialData]);

    // Handle the edit of the job
    const handleEdit = () => {
        // Call the onEdit prop with the updated data
        onEdit({ name, description, status, creatorId, workerId, projectId });
        onClose();

        // Clear the form (optional)
        setName('');
        setDescription('');
        setStatus('Open'); // Reset to "Open" after editing
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="edit-job-dialog-title"
            aria-describedby="edit-job-dialog-description"
        >
            <DialogTitle id="edit-job-dialog-title">Edit Job</DialogTitle>
            <DialogContent>

                {/* Name field */}
                <TextField
                    margin="dense"
                    label="Job Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />

                {/* Description field */}
                <TextField
                    margin="dense"
                    label="Job Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />

                {/* Status field */}
                <TextField
                    margin="dense"
                    label="Status"
                    select
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                >
                    {statusOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                {/* Cancel button */}
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>

                {/* Edit button */}
                <Button onClick={handleEdit} color="primary" autoFocus>
                    Edit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditJobDialog;
