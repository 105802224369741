import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { store } from './utils/store';
import './index.css'; // Optional if you have global styles
import { ThemeProvider } from "@mui/material/styles"
import { ApplicationProvider } from './utils/ApplicationProvider';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './assets/styles/theme'; // Import the theme

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ReduxProvider store={store}>
                <ApplicationProvider>
                    <App />
                </ApplicationProvider>
            </ReduxProvider>
        </ThemeProvider>
    </React.StrictMode>
);
