import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem } from '@mui/material';

const AddJobDialog = ({ open, onClose, onCreate, creatorId, workerId, projectId }) => {

    // Local state to manage form input
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState('Open'); // Set the default value to "Open"

    // Status options (for now only "Open")
    const statusOptions = ['Open'];

    // Handle the creation of the job
    const handleCreate = () => {
        // Call the onCreate prop with the collected data
        onCreate({ name, description, status, creatorId, workerId, projectId });
        onClose();

        // Clear the form
        setName('');
        setDescription('');
        setStatus('Open'); // Reset to "Open" after creating
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="add-job-dialog-title"
            aria-describedby="add-job-dialog-description"
        >
            <DialogTitle id="add-job-dialog-title">Create New Job</DialogTitle>
            <DialogContent>

                {/* Name field */}
                <TextField
                    margin="dense"
                    label="Job Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />

                {/* Description field */}
                <TextField
                    margin="dense"
                    label="Job Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />

                {/* Status field */}
                <TextField
                    margin="dense"
                    label="Status"
                    select
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                >
                    {statusOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                {/* Cancel button */}
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>

                {/* Create button */}
                <Button onClick={handleCreate} color="primary" autoFocus>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddJobDialog;
