import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { CheckCircle, Error } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

const AddImageDialog = ({ open, onClose, projectId, createImage, setImageRefetchKey }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState({});

    useEffect(() => {
        if (!open) {
            setSelectedFiles([]);  // Clear the list when dialog closes
            setUploadStatus({});
        }
    }, [open]);

    // Handle file selection via drag-and-drop
    const onDrop = (acceptedFiles) => {
        const imageFiles = acceptedFiles.filter(file =>
            file.type.startsWith('image/') && !selectedFiles.some(f => f.name === file.name)
        );
        setSelectedFiles(prevFiles => [...prevFiles, ...imageFiles]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
        },
        multiple: true,
    });

    // Handle the upload process, one-by-one
    const handleUpload = async () => {
        setUploading(true);
        const newStatus = { ...uploadStatus };

        for (const file of selectedFiles) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('quality', 100); // Default quality
            formData.append('isValid', true); // Default validity
            formData.append('isTrain', true); // Default train
            formData.append('tag', ""); // Default empty tag

            try {
                const retuls = await createImage({ projectId, formData });
                if (retuls.error) {
                    const errorMsg = retuls.error?.data?.detail || 'Error uploading file';
                    newStatus[file.name] = { status: 'error', message: errorMsg };
                } else{
                    newStatus[file.name] = { status: 'success' };
                }

            } catch (error) {
                newStatus[file.name] = { status: 'error', message: 'Internal Server Error' };
            }
            setUploadStatus({ ...newStatus });
        }

        setUploading(false);

        if (Object.values(newStatus).every(item => item.status === 'success')) {
            setImageRefetchKey(prevKey => prevKey + 1);
        }
    };

    // Render file list with success/error icons and hover tooltip
    const renderFileList = () => (
        <List sx={{ overflowY: 'auto', maxHeight: 200 }}> {/* Add scroll and set a maximum height */}
            {selectedFiles.map(file => (
                <ListItem
                    key={file.name}
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                    <ListItemText primary={file.name} />
                    {uploading && !uploadStatus[file.name] && <CircularProgress size={20} />}
                    {uploadStatus[file.name]?.status === 'success' && (
                        <Tooltip title="Success" arrow>
                            <ListItemIcon>
                                <CheckCircle color="success" />
                            </ListItemIcon>
                        </Tooltip>
                    )}
                    {uploadStatus[file.name]?.status === 'error' && (
                        <Tooltip title={uploadStatus[file.name]?.message} arrow>
                            <ListItemIcon>
                                <Error color="error" />
                            </ListItemIcon>
                        </Tooltip>
                    )}
                </ListItem>
            ))}
            {selectedFiles.length === 0 && <Typography>No files selected</Typography>}
        </List>
    );

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="add-image-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="add-image-dialog-title">Add Images</DialogTitle>
            <DialogContent>
                {/* Drag-and-drop area */}
                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed #ccc',
                        borderRadius: '8px',
                        padding: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        mt: 2,
                        bgcolor: '#f9f9f9',
                        transition: 'background-color 0.2s',
                        '&:hover': { bgcolor: '#f1f1f1' },
                    }}
                >
                    <input {...getInputProps()} />
                    <Typography>Drag & Drop image files here, or click to select files</Typography>
                    <Typography variant="body2" color="textSecondary">Only .png, .jpg, .jpeg files are allowed</Typography>
                </Box>

                {/* File list display */}
                <Box sx={{ mt: 2, flexGrow: 1 }}> {/* Ensure file list grows and fits */}
                    {renderFileList()}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={uploading}>
                    Return
                </Button>
                <Button
                    onClick={handleUpload}
                    color="primary"
                    disabled={selectedFiles.length === 0 || uploading}
                >
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddImageDialog;
