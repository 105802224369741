import { useState, useEffect } from 'react';
import { useGetAllLabelsQuery } from '../api/labelApi';

const useAllLabels = (projectId) => {
    const [allLabels, setAllLabels] = useState([]);
    const [totalLabels, setTotalLabels] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the initial page of labels
    const { data, error: fetchError, isLoading, refetch } = useGetAllLabelsQuery({ projectId, limit: 100, offset: 0});

    // Handle the response data from the hook
    useEffect(() => {
        if (isLoading) {
            setLoading(true);
            setError(null);
        } else if (fetchError) {
            setLoading(false);
            setError(fetchError);
        } else if (data) {
            const { items, total } = data;
            setAllLabels(items);
            setTotalLabels(total);
            setLoading(false);
        }
    }, [data, fetchError, isLoading]);

    // Function to load all labels
    const fetchAllLabels = async () => {
        let offset = 0;
        const allItems = [];

        // Fetch while more items exist
        while (offset < totalLabels) {
            const result = await refetch({ projectId, limit: 100, offset });
            if (result.error) {
                setError(result.error);
                break;
            }
            allItems.push(...result.data.items);
            offset += 100;
        }
        setAllLabels(allItems);
    };

    // Call fetchAllLabels when the totalLabels value updates
    useEffect(() => {
        if (totalLabels > 0) {
            fetchAllLabels();
        }
    }, [totalLabels]); // eslint-disable-line react-hooks/exhaustive-deps

    return { labels: allLabels, totalLabels, loading, error };
};

export default useAllLabels;
