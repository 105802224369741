import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SettingsIcon from '@mui/icons-material/Settings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ModeIcon from '@mui/icons-material/Mode';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const AdminDrawer = () => {
    const location = useLocation();
    const theme = useTheme();

    return (
        <List>
            {/* Dashboard Link */}
            <ListItem
                component={Link}
                to="/admin/dashboard"
                selected={location.pathname === '/admin/dashboard'}
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>

            {/* Annotate Management */}
            <ListItem
                component={Link}
                to="/admin/annotation"
                selected={location.pathname === '/admin/annotation'}
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <ModeIcon />
                </ListItemIcon>
                <ListItemText primary="Annotation" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>

            {/* Project Management */}
            <ListItem
                component={Link}
                to="/admin/projects"
                selected={location.pathname === '/admin/projects'}
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <WorkIcon />
                </ListItemIcon>
                <ListItemText primary="Projects" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>

            {/* User Management */}
            <ListItem
                component={Link}
                to="/admin/users"
                selected={location.pathname === '/admin/users'}
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Users" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>

            {/* Jobs Management */}
            <ListItem
                component={Link}
                to="/admin/jobs"
                selected={location.pathname === '/admin/jobs'}
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <EngineeringIcon />
                </ListItemIcon>
                <ListItemText primary="Jobs" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>

            {/* Logout */}
            <ListItem
                component={Link}
                to="/logout"
                sx={{ color: theme.palette.background.sidebarText }}
            >
                <ListItemIcon sx={{ color: theme.palette.background.sidebarText }}>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={{ ".MuiListItemText-primary": { color: theme.palette.background.sidebarText } }} />
            </ListItem>
        </List>
    );
};

export default AdminDrawer;
