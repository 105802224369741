import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

const AddUserDialog = ({ open, onClose, onCreate }) => {

    // Roles for the dropdown
    const roles = ['User'];

    // Local state to manage form input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(roles[0]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    // Email validation using a regex pattern
    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    // Password validation (min 8 characters, 1 number, 1 uppercase letter)
    const validatePassword = (password) => {
        const passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
        return passwordPattern.test(password);
    };

    // Handle changes to the email field
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setIsEmailValid(validateEmail(value));
    };

    // Handle changes to the password field
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setIsPasswordValid(validatePassword(value));
    };

    // Handle the creation of the user
    const handleCreate = () => {
        onCreate({ email, password, role, isAdmin });
        onClose();

        // Clear the form
        setEmail('');
        setPassword('');
        setRole(roles[0]);
        setIsAdmin(false);
    };

    // Button should be enabled only if both email and password are valid
    const isFormValid = isEmailValid && isPasswordValid;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="add-user-dialog-title"
            aria-describedby="add-user-dialog-description"
        >
            <DialogTitle id="add-user-dialog-title">Add New User</DialogTitle>
            <DialogContent>

                {/* Email field */}
                <TextField
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    error={!isEmailValid && email.length > 0}
                    helperText={!isEmailValid && email.length > 0 ? "Enter a valid email" : ""}
                />

                {/* Password field */}
                <TextField
                    margin="dense"
                    label="Password"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    error={!isPasswordValid && password.length > 0}
                    helperText={!isPasswordValid && password.length > 0 ? "Password must be at least 8 characters, with 1 number and 1 uppercase letter" : ""}
                />

                {/* Role selection */}
                <TextField
                    margin="dense"
                    label="Role"
                    select
                    fullWidth
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                >
                    {roles.map((roleOption) => (
                        <MenuItem key={roleOption} value={roleOption}>
                            {roleOption}
                        </MenuItem>
                    ))}
                </TextField>

                {/* Is Admin Checkbox */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isAdmin}
                            onChange={(e) => setIsAdmin(e.target.checked)}
                        />
                    }
                    label="Is Admin"
                />
            </DialogContent>
            <DialogActions>
                {/* Cancel button */}
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>

                {/* Create button, disabled if form is invalid */}
                <Button onClick={handleCreate} color="primary" autoFocus disabled={!isFormValid}>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserDialog;
