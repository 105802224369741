import React, {useEffect} from 'react';
import { Box, Typography, List, ListItem, ListItemText, Collapse, IconButton, Tooltip, Select, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore, Edit as EditIcon, Delete as DeleteIcon, Cancel as CancelIcon } from '@mui/icons-material';

// Helper function to extract the base name (before the comma)
const getBaseName = (name) => {
    return name.includes(',') ? name.split(',')[0].trim() : name.trim();
};

// Helper function to extract the variant (after the comma)
const getVariant = (name) => {
    return name.includes(',') ? name.split(',')[1]?.trim() : '';
};

// Custom medical order for the variants
const medicalOrder = [
    'N/A', '18', '17', '16', '15', '14', '13', '12', '11', '21', '22', '23', '24', '25', '26', '27', '28',
    '48', '47', '46', '45', '44', '43', '42', '41', '31', '32', '33', '34', '35', '36', '37', '38'
];

// Function to sort based on medical order
const sortAnnotationsByMedicalOrder = (annotations) => {
    return annotations.sort((a, b) => {
        const indexA = medicalOrder.indexOf(a.variant);
        const indexB = medicalOrder.indexOf(b.variant);

        // If both are found in the medicalOrder, sort based on the index
        if (indexA !== -1 && indexB !== -1) return indexA - indexB;

        // If one is missing, place the one in the order first
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        // If neither are in the medical order, keep original order
        return 0;
    });
};

const AnnotationsPanel = ({
    showAnnotationsPanel,
    annotationsByLabel,
    expandedLabels,
    handleToggleLabel,
    selectedAnnotationId,
    handleSelectAnnotation,
    handleEditAnnotation,
    handleDeleteAnnotation,
    isEditing,
    handleStopEditing,
    handleVariantChange,  // New prop for handling variant change
}) => {
    // Group annotations by base name
    const groupedAnnotations = Object.keys(annotationsByLabel).reduce((acc, labelName) => {
        const baseName = getBaseName(labelName);
        const variant = getVariant(labelName);

        if (!acc[baseName]) {
            acc[baseName] = [];
        }

        // Push each annotation object along with its variant
        annotationsByLabel[labelName].forEach((annotation) => {
            acc[baseName].push({ ...annotation, variant });
        });

        return acc;
    }, {});

    useEffect(() => {
        console.log(isEditing);
    }, [isEditing]);

    return (
        showAnnotationsPanel && (
            <Box
                sx={{
                    width: '300px',
                    marginLeft: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    padding: 2,
                    overflowY: 'auto',
                }}
            >
                {/* Title */}
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Annotations
                </Typography>

                {/* Annotations List */}
                <List>
                    {Object.keys(groupedAnnotations).map((baseName) => (
                        <React.Fragment key={baseName}>
                            {/* Create an item to group all the annotations under the base name */}
                            <ListItem
                                button
                                onClick={() => handleToggleLabel(baseName)}
                                sx={{
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: '4px',
                                    mb: 1,
                                }}
                            >
                                <ListItemText
                                    primary={baseName}
                                    primaryTypographyProps={{ fontWeight: 'bold' }}
                                />
                                {expandedLabels[baseName] ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>

                            {/* List of annotations for the base name */}
                            <Collapse in={expandedLabels[baseName]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {sortAnnotationsByMedicalOrder(groupedAnnotations[baseName]).map((ann, index) => (
                                        <ListItem
                                            key={ann.id}
                                            button
                                            selected={selectedAnnotationId === ann.id}
                                            onClick={() => handleSelectAnnotation(ann.id)}
                                            sx={{
                                                pl: 4,
                                                backgroundColor:
                                                    selectedAnnotationId === ann.id
                                                        ? '#cfe8fc'
                                                        : 'transparent',
                                                borderRadius: '4px',
                                                mb: 0.5,
                                            }}
                                        >
                                            {/* Conditionally render variant dropdown or fallback to default annotation name */}
                                            {ann.variant ? (
                                                <Select
                                                    value={ann.variant}
                                                    onChange={(e) => handleVariantChange(ann.id, e.target.value, getBaseName(ann.label.name))}
                                                    size="small"
                                                    sx={{ minWidth: 80, marginRight: 2 }}
                                                >
                                                    {medicalOrder.map((variant) => (
                                                        <MenuItem key={variant} value={variant}>
                                                            {variant}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <ListItemText primary={`Annotation ${index + 1}`} />
                                            )}

                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {/* Edit Annotation Button */}
                                                <Tooltip title="Edit Annotation">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="edit"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleEditAnnotation(ann.id);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                {/* Delete Annotation Button */}
                                                <Tooltip title="Delete Annotation">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteAnnotation(ann.id);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>

                {/* Stop Editing Button */}
                {isEditing && (
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <Tooltip title="Stop Editing">
                            <IconButton
                                color="secondary"
                                onClick={handleStopEditing}
                                sx={{ backgroundColor: '#f50057', color: '#fff' }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Box>
        )
    );
};

export default AnnotationsPanel;
