import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/job`, // Base URL for job-related API endpoints
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the job API
export const jobApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "jobApi",
    tagTypes: ["Job"],

    endpoints: (build) => ({

        // Get jobs by project, creator, and/or worker
        getJobs: build.query({
            query: (jobRequest) => ({
                url: "/getJobs",
                method: "POST",
                body: jobRequest, // Sends jobRequest (projectId, creatorId, workerId)
            }),
            providesTags: ["Job"],
        }),

        // Upload a new job
        uploadJob: build.mutation({
            query: (jobData) => ({
                url: "/uploadJob",
                method: "POST",
                body: jobData,
            }),
            invalidatesTags: ["Job"], // Invalidate jobs cache after a new job is created
        }),

        // Delete a job by id
        deleteJob: build.mutation({
            query: (jobId) => ({
                url: `/${jobId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Job"], // Invalidate jobs cache after a job is deleted
        }),

        // Edit a job by id
        editJob: build.mutation({
            query: ({ jobId, jobUpdateData }) => ({
                url: `/${jobId}`,
                method: "PUT",
                body: jobUpdateData, // Sends only the fields to be updated
            }),
            invalidatesTags: ["Job"], // Invalidate jobs cache after a job is edited
        }),

        // Get all images associated with a job (paginated)
        getJobContent: build.query({
            query: ({ jobId, offset = 0, limit = 100 }) => ({
                url: `/getJobContent/${jobId}?offset=${offset}&limit=${limit}`,
                method: "GET",
            }),
            providesTags: ["Job"],
        }),

        // Get all images associated with a job (paginated)
        getJobContentIds: build.query({
            query: ({ jobId }) => ({
                url: `/getJobContentIds/${jobId}`,
                method: "GET",
            }),
            providesTags: ["Job"],
        }),

        // Create new job content (Label)
        pushJobContent: build.mutation({
            query: ({ jobId, newJobContent }) => ({
                url: `/pushJobContent/${jobId}`,
                method: "POST",
                body: newJobContent, // Sends new job content (imageId, completed, etc.)
            }),
            invalidatesTags: ["Job"],
        }),

        // Create new job content (Label)
        editJobContent: build.mutation({
            query: ({ jobId, newJobContent }) => ({
                url: `/editJobContent/${jobId}`,
                method: "POST",
                body: newJobContent, // Sends new job content (imageId, completed, etc.)
            }),
            invalidatesTags: ["Job"],
        }),

        // Delete a label by id
        deleteJobImage: build.mutation({
            query: ({ jobId, imageId }) => ({
                url: `/deleteJobContent/${jobId}/${imageId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Label"],
        }),
    }),
});

// Export hooks for using the job API
export const {
    useLazyGetJobsQuery,
    useGetJobsQuery,
    useUploadJobMutation,
    useDeleteJobMutation,
    useEditJobMutation,
    useGetJobContentQuery,
    useGetJobContentIdsQuery,
    usePushJobContentMutation,
    useEditJobContentMutation,
    useDeleteJobImageMutation,
} = jobApi;
