import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';
import { useGetAllUsersQuery, useDeleteUserMutation, useCreateUserMutation } from '../../api/userApi';
import { Paper, ListItemText, Typography, Box, Button } from '@mui/material';

import { usePushJobContentMutation, useDeleteLabelMutation } from '../../api/jobApi';
import PaginatedList from '../../components/common/lists/PaginatedList';
import AddUserDialog from '../../components/common/dialogs/AddUserDialog';

import JobExplorerList from '../../components/common/lists/JobExplorerList';

const renderUser = (user, index) => (
    <ListItemText
        primary={`${index}. ${user.email}`} // Show index and email
        secondary={`Role: ${user.role} | Admin: ${user.isAdmin ? 'Yes' : 'No'}`}
    />
);

const Users = () => {
    // States
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);
    const [selectedUser, setSelectedUser] = useState(null);
    const [refetchKey, setRefetchKey] = useState(0);
    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
    const [deleteUserMutation] = useDeleteUserMutation();
    const [createUserMutation] = useCreateUserMutation();

    // Project and Images
    const [projectId, setProjectId] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(null);

    // Open the add user dialog when the add user button is clicked
    const addUser = () => {
        setAddUserDialogOpen(true);
    }

    // Create a new user with the information provided
    const createUser = async (newUser) => {
        try {
            const result = await createUserMutation(newUser);
            if (result.error) {
                await openNotificationDialog('Error', result.error.data.detail);
            }
            else {
                setRefetchKey(prevKey => prevKey + 1);
                setAddUserDialogOpen(false);
            }

        } catch (error) {
            console.error("Error creating user:", error);
        }
    };

    // Delete the selected user
    const deleteUser = async () => {
        // Check if selected user is null to return
        if (selectedUser) {
            try {
                // Ask the user if he want to delete the selected user
                const confirmation = await openConfirmationDialog('Delete User', `Are you sure you want to delete ${selectedUser.email}?`);
                if (!confirmation) return;

                // Check if the selected user is the current user
                if (selectedUser.id === user.id) {
                    await openNotificationDialog('Error', 'You cannot delete yourself.');
                    return;
                }

                // Call the mutation and await the result
                const result = await deleteUserMutation(selectedUser.id);

                // Handle the result or error accordingly
                if (result.error) {
                    await openNotificationDialog('Error', result.error.data.detail);
                } else{
                    // Refetch the list of users by incrementing the refresh key
                    setRefetchKey(prevKey => prevKey + 1);
                }
            } catch (err) {
                await openNotificationDialog('Error', 'An error occurred while deleting the user.');
            }
        }
    }

    return (
        // Grid layout of 4 by 4
        <Box display="grid" flexGrow={1} gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(12, 1fr)" gap={2} sx={{height: '100%', minHeight: 0}}>

            {/* List of users */}
            <Box gridColumn="span 3" gridRow="span 12" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <PaginatedList
                    refetchKey={refetchKey}
                    title={"Users"}
                    query={useGetAllUsersQuery}
                    renderItem={renderUser}
                    limit={50}
                    onItemSelected={setSelectedUser}
                    editEnable={true}
                    addItemCall={addUser}
                    editUserCall={() => console.log("Edit user")}
                    deleteUserCall={deleteUser}
                />
            </Box>

            {/* Projects and Jobs per user */}
            <Box gridColumn="4 / span 3" gridRow="span 12" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <JobExplorerList
                    setProjectId={setProjectId}
                    setSelectedJobId={setSelectedJobId}
                    workerId={selectedUser?.id || null}
                    changesToSave={false}
                />
            </Box>

            {/* Progress information */}
            <Box gridColumn="span 6" gridRow="span 12" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}>
                    <Typography variant="h5">Progress</Typography>
                    <Typography variant="body1">No progress information available.</Typography>
                </Paper>
            </Box>

            {/* Dialogs */}
            <AddUserDialog
                open={addUserDialogOpen}
                onClose={() => setAddUserDialogOpen(false)}
                onCreate={createUser}
            />
        </Box>
    );
};

export default Users;
