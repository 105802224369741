import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Base query for the auth API
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/auth`,
});

// Create the auth API
export const authApi = createApi({
    baseQuery,
    reducerPath: "authApi",
    tagTypes: ["Auth"],

    endpoints: (build) => ({
        // Login user with username and password
        loginUser: build.mutation({
            query: (loginDetails) => ({
                url: "/login",
                method: "POST",
                body: new URLSearchParams({
                    username: loginDetails.username,
                    password: loginDetails.password
                }),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }),
        }),
    }),
});

// Export hooks for using the auth API
export const {
    useLoginUserMutation,  // This is the hook for the login mutation
} = authApi;

console.log("Base API URL:", baseAPIURL);