import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { CheckCircleOutline, School } from '@mui/icons-material';
import { useEditImageMutation } from '../../../api/imageApi';
import { useAppContext } from '../../../utils/ApplicationProvider';

const ImageMetaData = ({ selectedImage }) => {
  const { openNotificationDialog } = useAppContext();

  // Form data state
  const [formData, setFormData] = useState({
    quality: selectedImage?.quality ?? 100,
    isValid: selectedImage?.isValid ?? false,
    isTrain: selectedImage?.isTrain ?? false,
    tag: selectedImage?.tag ?? '',
  });

  // Update formData when selectedImage changes
  useEffect(() => {
    if (selectedImage) {
      setFormData({
        quality: selectedImage.quality ?? 100,
        isValid: selectedImage.isValid ?? false,
        isTrain: selectedImage.isTrain ?? false,
        tag: selectedImage.tag ?? '',
      });
    } else {
      setFormData({
        quality: 100,
        isValid: false,
        isTrain: false,
        tag: '',
      });
    }
  }, [selectedImage]);

  // Mutation hook for editing image
  const [editImage, { isLoading }] = useEditImageMutation();

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare data for the PUT request
    const editData = {
      imageId: selectedImage.id,
      quality: parseInt(formData.quality, 10),
      isValid: formData.isValid,
      isTrain: formData.isTrain,
      tag: formData.tag ?? '',
    };

    try {
      const result = await editImage(editData).unwrap();

        // Wait until the mutation is successful
        // openNotificationDialog('Success', 'Image metadata updated successfully');
    } catch (error) {
      let errorMsg = 'Failed to edit image metadata';
      if (error.data && error.data.detail) {
        if (Array.isArray(error.data.detail)) {
          errorMsg = error.data.detail.map((err) => err.msg).join(', ');
        } else if (typeof error.data.detail === 'string') {
          errorMsg = error.data.detail;
        }
      }
      openNotificationDialog('Error', errorMsg);
    }
  };

  if (!selectedImage) {
    return (
      <Paper
        elevation={3}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: 0,
          padding: 2,
        }}
      >
        <Typography textAlign="center" variant="body1">
          No image selected.
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        padding: 2,
        position: 'relative',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Image Metadata
      </Typography>

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          {/* Checkboxes with Icons */}
          <FormControlLabel
            control={
              <Checkbox
                name="isValid"
                checked={formData.isValid}
                onChange={handleChange}
                size="small"
                disabled={isLoading}
              />
            }
            label={
              <Tooltip title="Valid">
                <CheckCircleOutline fontSize="small" />
              </Tooltip>
            }
            sx={{ m: 0 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isTrain"
                checked={formData.isTrain}
                onChange={handleChange}
                size="small"
                disabled={isLoading}
              />
            }
            label={
              <Tooltip title="Train">
                <School fontSize="small" />
              </Tooltip>
            }
            sx={{ m: 0 }}
          />

          {/* Quality Field */}
          <TextField
            label="Quality"
            name="quality"
            type="number"
            value={formData.quality}
            onChange={handleChange}
            inputProps={{ min: 0, max: 100 }}
            required
            size="small"
            variant="outlined"
            margin="dense"
            sx={{ width: 80 }}
            disabled={isLoading}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
            mb: 1,
          }}
        >
          {/* Tag Field */}
          <TextField
            label="Tag"
            name="tag"
            type="text"
            value={formData.tag}
            onChange={handleChange}
            size="small"
            variant="outlined"
            margin="dense"
            fullWidth
            disabled={isLoading}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          {/* Submit Button */}
          <Button
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </form>

      {/* Loading Indicator */}
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255,255,255,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Paper>
  );
};

export default ImageMetaData;
