import React, { useState, useEffect } from 'react';
import { Box, Paper, List, ListItem, Typography, Pagination, Button } from '@mui/material';

const PaginatedList = ({ refetchKey, title, query, renderItem, limit = 100, onItemSelected, editEnable, addItemCall, editItemCall, deleteUserCall, foreignKeyRequired, foreignKey }) => {
    // States
    const [offset, setOffset] = useState(0);
    const [items, setItems] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedItemId, setSelectedItemId] = useState(null);

    // Query data from the server based on the limit and offset
    const queryParams = foreignKeyRequired && foreignKey ? { projectId: foreignKey, limit, offset } : { limit, offset };
    const shouldQuery = !foreignKeyRequired || (foreignKeyRequired && foreignKey);
    const { data, refetch } = query(queryParams, { skip: !shouldQuery });

    // Refetch data when the refetchKey changes
    useEffect(() => {
        setItems([]);
        if (foreignKeyRequired && foreignKey) {
            refetch({ projectId: foreignKey, limit, offset });
        } else if (!foreignKeyRequired) {
            refetch({ limit, offset });
        }
    }, [refetchKey, foreignKey]); // eslint-disable-line react-hooks/exhaustive-deps

    // Fetch data for the next page and unselect the current item
    const handlePageChange = (event, newPage) => {
        if (newPage === page) return;
        const newOffset = (newPage - 1) * limit;
        setPage(newPage);
        setOffset(newOffset);
        setItems([]);
        setSelectedItemId(null);
    };

    // Handle item click to toggle selection
    const handleItemClick = (itemId) => {
        setSelectedItemId((prevSelected) => (prevSelected === itemId ? null : itemId));
    };

    // Ensure we are appending unique items only
    const appendUniqueItems = (newItems) => {

        // *************** Provisional fix ***************
        const nameToIdMap = new Map();  // Create a map to store the first item for each name

        // Iterate over newItems, keeping only the first occurrence of each name or email
        newItems.forEach(item => {
            // Use item.name if it exists, otherwise use item.email
            const identifier = item.name ? item.name : item.email;

            if (identifier) {
                // Split the identifier by comma and use only the first part as the baseName
                const baseName = identifier.includes(',') ? identifier.split(',')[0].trim() : identifier.trim();

                // Add the item to the map only if this is the first occurrence of the baseName
                if (!nameToIdMap.has(baseName)) {
                    // Create a new object with the modified name (or email)
                    const newItem = { ...item, name: baseName }; // Modifying the 'name' key, even if it was 'email'
                    nameToIdMap.set(baseName, newItem);  // Store the new object with modified name or email as baseName
                }
            } else {
                console.warn('Neither name nor email is defined for item:', item);
            }
        });

        // Now convert the map values back into an array
        const newItemsGroup = Array.from(nameToIdMap.values());
        // *************** End of the fix ***************

        const existingItemIds = new Set(items.map(item => item.id));
        const uniqueNewItems = newItemsGroup.filter(item => !existingItemIds.has(item.id));
        setItems(uniqueNewItems);
    };

    // Update the items list and total when new data arrives
    useEffect(() => {
        if (data) {
            const { items: newItems, total } = data;
            appendUniqueItems(newItems);
            setTotalItems(total);
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Notify parent when an item is selected or unselected (even if null)
    useEffect(() => {
        if (onItemSelected) {
            // Pass the entire item object to the parent
            const item = items.find(item => item.id === selectedItemId);
            onItemSelected(item);
        }
    }, [selectedItemId, onItemSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding:2}}>

            {/* Heading Centered */}
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                {title}
            </Typography>

            {/* List container with constrained height and scrollable overflow */}
            <Box
                sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 2, padding: 1, overflowY: 'auto', minHeight: 0}}>
                <List>
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <ListItem
                                key={item.id}
                                selected={item.id === selectedItemId}
                                onClick={() => handleItemClick(item.id)}
                                sx={{
                                    cursor: 'pointer',
                                    backgroundColor: item.id === selectedItemId ? 'primary.light' : 'inherit',
                                    '&:hover': {
                                        backgroundColor: 'primary.highlight',
                                        color: 'white',
                                    },
                                    transition: 'background-color 0.3s ease',
                                    borderRadius: '4px',
                                }}
                            >
                                {renderItem(item, index + 1 + offset)}
                            </ListItem>
                        ))
                    ) : (
                        <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                            No items to display
                        </Typography>
                    )}
                </List>
            </Box>

            {/* Display total number of items */}
            <Typography sx={{ marginTop: '2px', marginBottom:0, textAlign:'right' }}>
                Total: {totalItems}
            </Typography>

            {/* Pagination Component at the bottom */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
                <Pagination
                    count={Math.ceil(totalItems / limit)}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>

            {/* Control buttons */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, gap:2 }}>
                <Button variant="contained" color="primary" sx={{ flexGrow: 1 }} onClick={addItemCall}>Add</Button>
                {editEnable && <Button variant="contained" color="primary" sx={{ flexGrow: 1 }} onClick={editItemCall}>Edit</Button>}
                <Button variant="contained" color="error" sx={{ flexGrow: 1 }} onClick={deleteUserCall}>Delete</Button>
            </Box>

        </Paper>
    );
};

export default PaginatedList;
