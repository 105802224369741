import { jwtDecode } from 'jwt-decode';

// Simulate a login function (replace with real API call)
export const decodeToken = async (token) => {

    // Decode the token to get user info
    const decoded = jwtDecode(token);
    const user = {
        id: decoded.id,
        email: decoded.sub,
        role: decoded.role,
        admin: decoded.admin,
        exp: decoded.exp,
        iat: decoded.iat,
        nbf: decoded.nbf,
    };

    return { token, user };
};