import React, { useState } from 'react';
import { Box, MenuItem, Select, FormControl, InputLabel} from '@mui/material';
import { useSelector } from 'react-redux';
import useAllProjects from '../../../hooks/useAllProjects';

const ProjectSelector = ({ setProjectId }) => {
    // Context
    const { user } = useSelector((state) => state.auth);

    // Projects
    const { projects } = useAllProjects();
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    // Handle project selection change
    const handleProjectChange = (event) => {
        const projectId = event.target.value;
        setSelectedProjectId(projectId);
        setProjectId(projectId);
    };

    return (
        <>
            {/* Project Selector */}
            <FormControl fullWidth >
                <InputLabel id="project-selector-label">Select Project</InputLabel>
                <Select
                    labelId="project-selector-label"
                    value={selectedProjectId? selectedProjectId : ''}
                    onChange={handleProjectChange}
                    label="Select Project"
                >
                    {projects?.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                            {project.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default ProjectSelector;
