import { useEffect, useState } from "react";
import { useGetStatsQuery } from "../api/imageApi";
import { useGetAnnotationStatsQuery } from "../api/annotationApi";
import { useGetProgressStatsQuery } from "../api/progressApi";

export const useStats = (projectId) => {
    const [stats, setStats] = useState(null);
    const [annotationStats, setAnnotationStats] = useState(null);
    const [progressStats, setProgressStats] = useState(null);

    // Query for stats with refetch on mount or argument change
    const { data: statsData, error: statsError, isLoading: isLoadingStats, refetch } = useGetStatsQuery(projectId, {
        skip: !projectId,
        refetchOnMountOrArgChange: true,  // Force refetch on component mount or projectId change
    });

    // Query for annotation stats with refetch on mount or argument change
    const { data: annotationStatsData, error: annotationStatsError, isLoading: isLoadingAnnotationStats } = useGetAnnotationStatsQuery(projectId, {
        skip: !projectId,
        refetchOnMountOrArgChange: true,  // Force refetch on component mount or projectId change
    });

    // Query for progress stats with refetch on mount or argument change
    const { data: progressStatsData, error: progressStatsError, isLoading: isLoadingProgressStats } = useGetProgressStatsQuery(projectId, {
        skip: !projectId,
        refetchOnMountOrArgChange: true,  // Force refetch on component mount or projectId change
    });

    useEffect(() => {
        if (statsData) {
            setStats(statsData);
        }
    }, [statsData]);

    useEffect(() => {
        if (annotationStatsData) {
            setAnnotationStats(annotationStatsData);
        }
    }, [annotationStatsData]);

    useEffect(() => {
        if (progressStatsData) {
            setProgressStats(progressStatsData);
        }
    }, [progressStatsData]);

    return {
        stats, isLoadingStats, statsError,
        annotationStats, isLoadingAnnotationStats, annotationStatsError,
        progressStats, isLoadingProgressStats, progressStatsError,
        refetch
    };
};
