import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';
import { useGetAllProjectsQuery, useDeleteProjectMutation, useCreateProjectMutation } from '../../api/projectApi';
import { useGetAllLabelsQuery, useDeleteLabelMutation, useCreateLabelMutation, useEditLabelMutation } from '../../api/labelApi';
import { useDeleteImageMutation, useEditImageMutation, useGetAllImagesQuery, useCreateImageMutation } from '../../api/imageApi';
import { Paper, ListItemText, Typography, Box } from '@mui/material';
import PaginatedList from '../../components/common/lists/PaginatedList';
import AddProjectDialog from '../../components/common/dialogs/AddProjectDialog';
import AddLabelDialog from '../../components/common/dialogs/AddLabelDialog';
import EditLabelDialog from '../../components/common/dialogs/EditLabelDialog';
import AddImageDialog from '../../components/common/dialogs/AddImageDialog';
import ImageDisplay from '../../components/common/image/ImageDisplay';
import ImageMetaData from '../../components/common/image/ImageMetaData';

const renderProject = (project, index) => {
    // Convert and format the createdAt date to dd/mm/yy
    const formattedDate = new Date(project.createdAt).toLocaleDateString('en-GB'); // 'en-GB' is for dd/mm/yyyy format

    return (
        <ListItemText
            primary={`${index}. ${project.name}`}
            secondary={`Created on: ${formattedDate}`}
        />
    );
};

const renderLabel = (label, index) => {
    // Convert the color to a background color for display
    const labelStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    };

    const colorBoxStyle = {
        width: '16px',
        height: '16px',
        backgroundColor: label.color,  // Set the background color to the label color
        borderRadius: '50%', // Make it a circle
    };

    return (
        <ListItemText
            primary={
                <Box sx={labelStyle}>
                    <Box sx={colorBoxStyle}></Box>
                    {`${index}. ${label.name}`}  {/* Display the index and the name */}
                </Box>
            }
            secondary={`Type: ${label.type}`}  // Display the type as secondary text
        />
    );
};

const renderImage = (image, index) => {
    // Define a style for the container
    const imageStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    };

    // Style for the validity indicator (green for valid, red for invalid)
    const validityStyle = {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: image.isValid ? 'green' : 'red',
    };

    return (
        <ListItemText
            primary={`${index}. ${image.name}`} // Display the image name with index
            secondary={
                <Box sx={imageStyle}>
                    <Box sx={validityStyle} /> {/* Show the validity circle */}
                    <span>{image.isValid ? 'Valid' : 'Invalid'}</span> {/* Display valid/invalid */}
                    <span>Quality: {image.quality}</span>
                </Box>
            }
        />
    );
};

const Projects = () => {
    // States
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);

    // *************** Project Management ***************
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectRefetchKey, setProjectRefetchKey] = useState(0);
    const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false);
    const [deleteProjectMutation] = useDeleteProjectMutation();
    const [createProjectMutation] = useCreateProjectMutation();

    // Open the add user dialog when the add user button is clicked
    const addProject = () => {
        setAddProjectDialogOpen(true);
    }

    // Create a new project with the information provided
    const createProject = async (newProject) => {
        try {
            const params = {name: newProject.name, creatorId: user.id};
            const result = await createProjectMutation(params);
            if (result.error) {
                await openNotificationDialog('Error', result.error.data.detail);
            }
            else {
                setProjectRefetchKey(prevKey => prevKey + 1);
                setAddProjectDialogOpen(false);
            }

        } catch (error) {
            openNotificationDialog('Error', 'An error occurred while creating the project.');
        }
    };

    // Delete the selected project
    const deleteProject = async () => {
        // Check if selected project is null to return
        if (selectedProject) {
            try {
                // Ask the project if he want to delete the selected project
                const confirmation = await openConfirmationDialog('Delete Project', `Are you sure you want to delete ${selectedProject.name}?`);
                if (!confirmation) return;

                // Call the mutation and await the result
                const result = await deleteProjectMutation(selectedProject.id);

                // Handle the result or error accordingly
                if (result.error) {
                    await openNotificationDialog('Error', result.error.data.detail);
                } else{
                    // Refetch the list of projects by incrementing the refresh key
                    setProjectRefetchKey(prevKey => prevKey + 1);
                    setSelectedProject(null);
                }
            } catch (err) {
                await openNotificationDialog('Error', 'An error occurred while deleting the project.');
            }
        }
    }

    // *************** Label Management ***************
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [labelRefetchKey, setLabelRefetchKey] = useState(0);
    const [addLabelDialogOpen, setAddLabelDialogOpen] = useState(false);
    const [editLabelDialogOpen, setEditLabelDialogOpen] = useState(false);
    const [deleteLabelMutation] = useDeleteLabelMutation();
    const [createLabelMutation] = useCreateLabelMutation();
    const [editLabelMutation] = useEditLabelMutation();

    // Open the add user dialog when the add user button is clicked
    const addLabel = () => {
        // If there is no selected project, return
        if (!selectedProject) {
            openNotificationDialog('Error', 'Please select a project to add a label.');
            return;
        }
        setAddLabelDialogOpen(true);
    }

    // Open the dialog to edit the selected label
    const editLabelDialog = () => {
        if (!selectedLabel) {
            openNotificationDialog('Error', 'Please select a label to edit.');
            return;
        }
        setEditLabelDialogOpen(true);
    }

    // Create a new project with the information provided
    const createLabel = async (newLabel) => {
        try {
            const params = {name: newLabel.name, color: newLabel.color, type: newLabel.type, projectId: selectedProject.id};
            const result = await createLabelMutation(params);
            if (result.error) {
                await openNotificationDialog('Error', result.error.data.detail);
            }
            else {
                setLabelRefetchKey(prevKey => prevKey + 1);
                setAddLabelDialogOpen(false);
            }

        } catch (error) {
            openNotificationDialog('Error', 'An error occurred while creating the label.');
        }
    };

    const editLabel = async (newLabel) => {
        try {
            const params = {color: newLabel.color, labelId: selectedLabel.id};
            const result = await editLabelMutation(params);
            if (result.error) {
                await openNotificationDialog('Error', result.error.data.detail);
            }
            else {
                setLabelRefetchKey(prevKey => prevKey + 1);
                setAddLabelDialogOpen(false);
            }

        } catch (error) {
            openNotificationDialog('Error', 'An error occurred while creating the label.');
        }
    };

    // Delete the selected project
    const deleteLabel = async () => {
        // Check if selected project is null to return
        if (selectedLabel) {
            try {
                // Ask the project if he want to delete the selected project
                const confirmation = await openConfirmationDialog('Delete Label', `Are you sure you want to delete ${selectedLabel.name}?`);
                if (!confirmation) return;

                // Call the mutation and await the result
                const result = await deleteLabelMutation(selectedLabel.id);

                // Handle the result or error accordingly
                if (result.error) {
                    await openNotificationDialog('Error', result.error.data.detail);
                } else{
                    // Refetch the list of projects by incrementing the refresh key
                    setLabelRefetchKey(prevKey => prevKey + 1);
                }
            } catch (err) {
                await openNotificationDialog('Error', 'An error occurred while deleting the label.');
            }
        }
    }


    // *************** Image Management ***************
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageRefetchKey, setImageRefetchKey] = useState(0);
    const [addImageDialogOpen, setAddImageDialogOpen] = useState(false);
    const [deleteImageMutation] = useDeleteImageMutation();
    const [editImageMutation] = useEditImageMutation();
    const [createImageMutation] = useCreateImageMutation();


    // Open the add user dialog when the add user button is clicked
    const addImage = () => {
        // If there is no selected project, return
        if (!selectedProject) {
            openNotificationDialog('Error', 'Please select a project to add an image.');
            return;
        }
        setAddImageDialogOpen(true);
    }

    // Delete the selected project
    const deleteImage = async () => {
        // Check if selected project is null to return
        if (selectedImage) {
            try {
                // Ask the project if he want to delete the selected project
                const confirmation = await openConfirmationDialog('Delete Image', `Are you sure you want to delete ${selectedImage.name}?`);
                if (!confirmation) return;

                // Call the mutation and await the result
                const result = await deleteImageMutation(selectedImage.id);

                // Handle the result or error accordingly
                if (result.error) {
                    await openNotificationDialog('Error', result.error.data.detail);
                } else{
                    // Refetch the list of projects by incrementing the refresh key
                    setImageRefetchKey(prevKey => prevKey + 1);
                }
            } catch (err) {
                await openNotificationDialog('Error', 'An error occurred while deleting the image.');
            }
        }
    }

    // *************** General logic ***************
    // Set to null the selected image or selected label when the selected project changes
    useEffect(() => {
        setSelectedImage(null);
        setSelectedLabel(null);
    }, [selectedProject]);




    return (
        // Grid layout of 4 by 4
        <Box display="grid" flexGrow={1} gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(4, 1fr)" gap={2} sx={{height: '100%', minHeight: 0}}>

            {/* List of projects */}
            <Box gridColumn="span 3" gridRow="span 2" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>

                {/* Paginated list of projects */}
                <PaginatedList
                    refetchKey={projectRefetchKey}
                    title={"Projects"}
                    query={useGetAllProjectsQuery}
                    renderItem={renderProject}
                    limit={50}
                    onItemSelected={setSelectedProject}
                    editEnable={false}
                    addItemCall={addProject}
                    deleteUserCall={deleteProject}
                    foreignKeyRequired={false}
                    foreignKey={null}
                />
            </Box>

            {/* Selected project information */}
            <Box gridColumn="span 3" gridRow="3 / span 2" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                {/* Paginated list of labels */}
                <PaginatedList
                    refetchKey={labelRefetchKey}
                    title={"Labels"}
                    query={useGetAllLabelsQuery}
                    renderItem={renderLabel}
                    limit={50}
                    onItemSelected={setSelectedLabel}
                    editEnable={true}
                    editItemCall={editLabelDialog}
                    addItemCall={addLabel}
                    deleteUserCall={deleteLabel}
                    foreignKeyRequired={true}
                    foreignKey={selectedProject ? selectedProject.id? selectedProject.id : null : null}
                />
            </Box>

            {/* Example: A box that spans 6 columns */}
            <Box gridColumn="span 3" gridRow="span 4" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                {/* Paginated list of images */}
                <PaginatedList
                    refetchKey={imageRefetchKey}
                    title={"Images"}
                    query={useGetAllImagesQuery}
                    renderItem={renderImage}
                    limit={50}
                    onItemSelected={setSelectedImage}
                    editEnable={false}
                    addItemCall={addImage}
                    deleteUserCall={deleteImage}
                    foreignKeyRequired={true}
                    foreignKey={selectedProject ? selectedProject.id? selectedProject.id : null : null}
                />
            </Box>

            {/* Example: A box that spans all 12 columns */}
            <Box gridColumn="span 6" gridRow="span 3" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <ImageDisplay
                    selectedImage={selectedImage? selectedImage : null}
                />
            </Box>

            {/* Example: A box that spans all 12 columns */}
            {/* <Box gridColumn="span 6" gridRow="span 1" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <ImageMetaData
                    selectedImage={selectedImage? selectedImage : null}
                    setImageRefetchKey={setImageRefetchKey}
                />
            </Box> */}

            {/* Dialogs */}
            <AddProjectDialog
                open={addProjectDialogOpen}
                onClose={() => setAddProjectDialogOpen(false)}
                onCreate={createProject}
            />

            <AddLabelDialog
                open={addLabelDialogOpen}
                onClose={() => setAddLabelDialogOpen(false)}
                onCreate={createLabel}
            />

            <EditLabelDialog
                open={editLabelDialogOpen}
                onClose={() => setEditLabelDialogOpen(false)}
                onSave={editLabel}
                labelName={selectedLabel ? selectedLabel.name : ''}
                labelType={selectedLabel ? selectedLabel.type : ''}
                color={selectedLabel ? selectedLabel.color : '#ffffff'}
            />

            <AddImageDialog
                open={addImageDialogOpen}
                onClose={() => setAddImageDialogOpen(false)}
                projectId={selectedProject ? selectedProject.id? selectedProject.id : null : null}
                createImage={createImageMutation}
                setImageRefetchKey={setImageRefetchKey}
            />

        </Box>
    );
};

export default Projects;
