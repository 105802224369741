import React, { useState } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, Skeleton } from '@mui/material';

// Custom components
import ProjectSelector from '../../components/common/selector/ProjectSelector';
import ProjectStats from '../../components/common/charts/ProjectStats';
import FrequencyStats from '../../components/common/charts/FrequencyStats';
import BacklogStats from '../../components/common/charts/BacklogStats';

// List of items with the analysis selector
const analysisAvailable = [
    {name: 'Frequency', description: 'Number of annotations per label', id: 1},
    {name: 'Backlog', description: 'Tasks in progress', id: 2},
];

const renderAnalysis = (analysis, index) => {
    return (
        <ListItemText
            primary={`${index}. ${analysis.name}`}
            secondary={`${analysis.description}`}
        />
    );
};

const Dashboard = () => {

    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [selectedAnalysisId, setSelectedAnalysisId] = useState(null);

    const handleAnalysisClick = (analysisId) => {
        setSelectedAnalysisId((prevSelected) => (prevSelected === analysisId ? null : analysisId));
    }

    return (

        <Box display="grid" flexGrow={1} gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(20, 1fr)" gap={2} sx={{height: '100%', minHeight: 0}}>

            {/* Basic stats */}
            <Box gridColumn="span 12" gridRow="span 2" sx={{ display: 'grid', gridTemplateColumns:"repeat(12, 1fr)", gap: 2, height: '100%', alignItems: 'center', minHeight: 0, flexGrow: 1 }}>

                {/* ProjectSelector taking 3/12 of the space */}
                <Box sx={{ gridColumn: 'span 3' }}>
                    <ProjectSelector setProjectId={setSelectedProjectId} />
                </Box>

                {/* ProjectStats taking 9/12 of the space */}
                <Box sx={{ gridColumn: 'span 9' }}>
                    <ProjectStats projectId={selectedProjectId} />
                </Box>

            </Box>

            {/* Analysis Selector */}
            <Box gridColumn="span 3" gridRow="span 18" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>
                <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding:2}}>
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                        Analysis Selector
                    </Typography>

                    {/* List container with constrained height and scrollable overflow */}
                    <Box
                        sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 2, padding: 1, overflowY: 'auto', minHeight: 0}}>
                        <List>
                            {analysisAvailable.length > 0 ? (
                                analysisAvailable.map((item, index) => (
                                    <ListItem
                                        key={item.id}
                                        selected={item.id === selectedAnalysisId}
                                        onClick={() => handleAnalysisClick(item.id)}
                                        sx={{
                                            cursor: 'pointer',
                                            backgroundColor: item.id === selectedAnalysisId ? 'primary.light' : 'inherit',
                                            '&:hover': {
                                                backgroundColor: 'primary.highlight',
                                                color: 'white',
                                            },
                                            transition: 'background-color 0.3s ease',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {renderAnalysis(item, index + 1)}
                                    </ListItem>
                                ))
                            ) : (
                                <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                                    No items to display
                                </Typography>
                            )}
                        </List>
                    </Box>

                </Paper>
            </Box>

            {/* Chart Area */}
            <Box gridColumn="span 9" gridRow="span 18" sx={{ display: 'flex', flexDirection: 'column', height: '100%', minHeight: 0, flexGrow:1 }}>

                {selectedAnalysisId === 1 ? (
                    <FrequencyStats projectId={selectedProjectId} />
                ) : selectedAnalysisId === 2 ? (
                    <BacklogStats projectId={selectedProjectId} />
                ) : selectedAnalysisId === 3 ? (
                    <></>
                ) : (
                    <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} />
                )}

            </Box>
        </Box>
    );
};

export default Dashboard;
