import React from 'react';
import { Box, Typography, Paper, Divider, LinearProgress } from '@mui/material';
import { useStats } from '../../../hooks/useStats';
import { styled } from '@mui/system';

// Styled components for aesthetic
const StatsBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '16px',
    justifyContent: 'space-between',
    width: '100%',
});

const StatItem = styled(Box)({
    textAlign: 'center',
    flexGrow: 1,
    padding: '0 16px',
});

const QualityBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
});

const ProjectStats = ({ projectId }) => {
    const { stats, isLoadingStats } = useStats(projectId);

    // Placeholder when stats are not available
    const placeholder = "-";

    return (
        <Box sx={{ width: '100%' }}>
            <StatsBox>

                {/* Total Images */}
                <StatItem>
                    <Typography variant="subtitle1">Total Images</Typography>
                    <Typography variant="h5">{stats ? stats.totalImages : placeholder}</Typography>
                </StatItem>

                <Divider orientation="vertical" flexItem />

                {/* Valid Images */}
                <StatItem>
                    <Typography variant="subtitle1">Valid Images</Typography>
                    <Typography variant="h5">{stats ? stats.validImages : placeholder}</Typography>
                </StatItem>

                <Divider orientation="vertical" flexItem />

                {/* Training Images */}
                <StatItem>
                    <Typography variant="subtitle1">Training Images</Typography>
                    <Typography variant="h5">{stats ? stats.trainingImages : placeholder}</Typography>
                </StatItem>

                <Divider orientation="vertical" flexItem />

                {/* Quality */}
                <QualityBox>
                    <Typography variant="subtitle1">Valid Images Quality</Typography>
                    {stats ? (
                        <>
                            <LinearProgress
                                variant="determinate"
                                value={(stats.quality.average / stats.quality.max) * 100}
                                sx={{ width: '100%', height: 10, borderRadius: '5px', marginBottom: '8px' }}
                            />
                            <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
                                <Typography variant="body2" color="textSecondary">
                                    Mean: {stats.quality.average.toFixed(2)}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Std: {stats.quality.std.toFixed(2)}
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Typography variant="h5">{placeholder}</Typography>
                    )}
                </QualityBox>

            </StatsBox>
        </Box>
    );
};

export default ProjectStats;
