import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import { Bar } from 'react-chartjs-2';
import { Box, Paper, Skeleton, Typography, ToggleButton } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useStats } from '../../../hooks/useStats';
import { getContrastColor } from '../../../utils/colorUtils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BacklogStats = ({ projectId }) => {
    const { progressStats, isLoadingProgressStats } = useStats(projectId);
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState({});
    const [visibleLabels, setVisibleLabels] = useState([]); // Labels to toggle visibility
    const chartRef = useRef(null); // Create a ref to access the chart instance

    useEffect(() => {
        if (progressStats) {
            const labels = Object.keys(progressStats).map(id => progressStats[id].name);
            const colors = Object.keys(progressStats).map(id => progressStats[id].color);
            const completedData = Object.keys(progressStats).map(id => progressStats[id].completed);
            const nonCompletedData = Object.keys(progressStats).map(id => progressStats[id].nonCompleted);

            setVisibleLabels(labels);

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'Completed',
                        data: completedData,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                    {
                        label: 'Non-Completed',
                        data: nonCompletedData,
                        backgroundColor: '#f5f5f5',
                        borderColor: '#ccc',
                        borderWidth: 1,
                    },
                ],
            });

            setChartOptions({
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y', // Horizontal Bar Chart
                plugins: {
                    legend: {
                        display: true,
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
                scales: {
                    x: {
                        beginAtZero: true,
                    },
                },
            });
        }
    }, [progressStats]);

    const handleToggleLabelVisibility = (labelName) => {
        setVisibleLabels((prevVisibleLabels) =>
            prevVisibleLabels.includes(labelName)
                ? prevVisibleLabels.filter((name) => name !== labelName)
                : [...prevVisibleLabels, labelName]
        );
    };

    const filteredChartData = chartData
    ? {
          ...chartData,
          labels: chartData.labels.filter((label) => visibleLabels.includes(label)),
          datasets: chartData.datasets.map((dataset) => ({
              ...dataset,
              data: dataset.data.filter((_, index) => visibleLabels.includes(chartData.labels[index])),
              backgroundColor: Array.isArray(dataset.backgroundColor)
                  ? dataset.backgroundColor.filter((_, index) => visibleLabels.includes(chartData.labels[index]))
                  : dataset.backgroundColor, // Use it as is if it's not an array
              borderColor: Array.isArray(dataset.borderColor)
                  ? dataset.borderColor.filter((_, index) => visibleLabels.includes(chartData.labels[index]))
                  : dataset.borderColor, // Use it as is if it's not an array
          })),
      }
    : null;

    return (
        <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}>
            {/* Configuration options at the top */}
            {filteredChartData ? (
                <>
                    {/* Chart Area */}
                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', marginBottom: 2 }}>
                        Backlog Stats
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            padding: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Bar ref={chartRef} data={filteredChartData} options={chartOptions} />
                    </Box>

                    {/* Label Visibility Toggle */}
                    <Box sx={{ marginTop: 2, padding: 1, backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
                        <Typography>Toggle Label Visibility</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {chartData &&
                                chartData.labels.map((label, index) => (
                                    <ToggleButton
                                        key={label}
                                        value={label}
                                        selected={visibleLabels.includes(label)}
                                        onClick={() => handleToggleLabelVisibility(label)}
                                        sx={{
                                            height: 32,
                                            borderColor: chartData.datasets[0].borderColor[index],
                                            color: chartData.datasets[0].borderColor[index],
                                            backgroundColor: 'transparent',
                                            '&:hover': {
                                                backgroundColor: chartData.datasets[0].borderColor[index],
                                                color: getContrastColor(chartData.datasets[0].borderColor[index]),
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: chartData.datasets[0].borderColor[index],
                                                color: getContrastColor(chartData.datasets[0].borderColor[index]),
                                                '&:hover': {
                                                    backgroundColor: chartData.datasets[0].borderColor[index],
                                                },
                                            },
                                            borderWidth: 2,
                                            textTransform: 'none',
                                        }}
                                    >
                                        {label}
                                    </ToggleButton>
                                ))}
                        </Box>
                    </Box>
                </>
            ) : (
                <Skeleton variant="rectangular" sx={{ flexGrow: 1 }} />
            )}
        </Paper>
    );
};

export default BacklogStats;
