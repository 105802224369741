import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import { drawerWidth } from './config';
import { useAppContext } from '../../utils/ApplicationProvider';

// Main content area
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isAuthenticated' })(({ theme, open, isAuthenticated }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: !isAuthenticated ? 0 : open ? 0 : `-${drawerWidth}px`,
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: theme.mixins.toolbar.minHeight,
    overflow: 'hidden',
    ...(isAuthenticated && open && {
        marginLeft: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

// Children container
const ChildrenContainer = styled(Box)(() => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    minHeight: 0,
}));

const Layout = ({ children }) => {
    // States
    const { user, isAuthenticated } = useSelector((state) => state.auth);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { setSidebarToggle } = useAppContext();

    // Handle drawer open
    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
        setSidebarToggle((prevState) => !prevState);
    };

    // Handle drawer close
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
        setSidebarToggle((prevState) => !prevState);
    };

    // Effect to close the drawer when the user logs out
    useEffect(() => {
        if (!isAuthenticated) {
            setIsDrawerOpen(false);
        }
    }, [isAuthenticated]);

    return (
        <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
            {/* Topbar */}
            <Topbar open={isDrawerOpen} handleDrawerOpen={handleDrawerOpen} />

            {/* Sidebar */}
            {isAuthenticated && (
                <Sidebar open={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
            )}

            {/* Main content area */}
            <Main open={isDrawerOpen} isAuthenticated={isAuthenticated}>
                <ChildrenContainer>
                    {children}
                </ChildrenContainer>
            </Main>
        </Box>
    );
};

export default Layout;
