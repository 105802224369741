import React, { useState } from 'react';
import { useAppContext } from '../utils/ApplicationProvider';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLoginUserMutation } from '../api/authApi';
import { decodeToken } from '../services/authService';
import { TextField, Button, Typography, Box, Paper } from '@mui/material';
import { login } from '../utils/authSlice';


const Login = () => {
    // Call the lazy query
    const [loginUser, { isLoading, error }] = useLoginUserMutation();
    const { openNotificationDialog } = useAppContext();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = async () => {
        try {
            const result = await loginUser({ username, password });

            if (result.data) {
                // Decode the token to get user info
                const {token, user} = await decodeToken(result.data.access_token);

                // Login the user
                dispatch(login({ token, user, expiresAt: user.exp }));
                navigate('/dashboard');

            } else if (result.error) {
                openNotificationDialog('Error', result.error.data.detail);
            }
        } catch (err) {
            openNotificationDialog('Unexpected Error', err);
        }
    };


    return (
        // Create a Box to use the entire height of the view
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>

            {/* Create the login form in a box in the middle of the view using Paper */}
            <Paper elevation = {10} sx={{ padding: '16px', width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {/* Title of the form */}
                <Typography variant="h2" gutterBottom>
                    DashIA
                </Typography>

                {/* Username and password fields, make sure the padding is bigger than the Paper padding */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%' }}>

                    <TextField
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        fullWidth
                        margin="normal"
                        padding="16px"
                    />

                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />

                    <Button variant="contained" color="primary" onClick={handleLogin} sx={{margin: '1rem 0' , padding: '0.5rem 2rem'}}>
                        Login
                    </Button>
                </Box>
            </Paper>

        </Box>
    );
};

export default Login;
