import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  Typography,
  Pagination,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListItemText,
} from '@mui/material';
import { Refresh, Clear, FilterList } from '@mui/icons-material';

import { useAppContext } from '../../../utils/ApplicationProvider';
import { useSelector } from 'react-redux';

import useAllProjects from '../../../hooks/useAllProjects';
import { useGetFilteredImagesQuery } from '../../../api/imageApi';

import FilterDialog from '../dialogs/FilterDialog';

const renderItem = (image, index) => {
  return <ListItemText primary={`${index}. ${image.name}`} />;
};

const AdminExplorerList = ({ setProjectId, setSelectedImage, setSelectedFullImage, changesToSave, changeBlocked }) => {
    // Context
    const { openNotificationDialog, openConfirmationDialog } = useAppContext();
    const { user } = useSelector((state) => state.auth);

    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [filters, setFilters] = useState([]);

    // Projects
    const { projects } = useAllProjects();
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    // Pagination
    const [page, setPage] = useState(1);
    const limit = 50;
    const offset = (page - 1) * limit;

    // Selected Image
    const [selectedItemId, setSelectedItemId] = useState(null);

    // Fetch images using the new query
    const { data: imageData, error: imageError, isLoading: imagesLoading, refetch: refetchImages} = useGetFilteredImagesQuery(
        {projectId: selectedProjectId, limit, offset, filterRequest: { filters }},
        {skip: !selectedProjectId}
    );

    const items = imageData?.items || [];
    const totalItems = imageData?.total || 0;

    // Handle project selection change
    const handleProjectChange = (event) => {
        const projectId = event.target.value;
        setSelectedProjectId(projectId);
        setSelectedItemId(null);
        setProjectId(projectId);
        setPage(1); // Reset to first page
    };

    const handleItemClick = async (itemId) => {
        // If changeBlocked is true, prevent user from selecting another item
        if (changeBlocked) {
        openNotificationDialog('Changes Blocked', 'You must finish the edition or annotation.');
        return;
        }

        // If changesToSave is true, prompt user to save changes
        if (changesToSave) {
        const result = await openConfirmationDialog(
            'Unsaved Changes',
            'You have unsaved changes. Do you want to continue?'
        );
        if (!result) return;
        }
        setSelectedItemId((prevSelected) => (prevSelected === itemId ? null : itemId));
    };

    useEffect(() => {
        setSelectedImage(selectedItemId);
        const fullImage = items.find((item) => item.id === selectedItemId);
        setSelectedFullImage(fullImage);
    }, [selectedItemId, setSelectedImage, setSelectedFullImage]); // eslint-disable-line

    // Function to open the dialog
    const openFilterDialogHandler = () => {
        setFilterDialogOpen(true);
    };

    // Function to handle applied filters
    const handleApplyFilters = (appliedFilters) => {
        if (selectedProjectId === null) {
            openNotificationDialog('Select Project', 'Please select a project first.');
            return;
        }
        setFilters(appliedFilters);
        setPage(1);
    };

    // Handle page change
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Handle reset filters
    const handleResetFilters = () => {
        if (selectedProjectId === null) {
            openNotificationDialog('Select Project', 'Please select a project first.');
            return;
        }
        setFilters([]);
        setPage(1);
    };

    // Handle refresh
    const handleRefresh = () => {
        if (selectedProjectId === null) {
            openNotificationDialog('Select Project', 'Please select a project first.');
            return;
        }
        refetchImages();
    };

    return (
        <Paper
        elevation={3}
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: 0, padding: 2 }}
        >
        {/* Project Selector */}
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="project-selector-label">Select Project</InputLabel>
            <Select
            labelId="project-selector-label"
            value={selectedProjectId ? selectedProjectId : ''}
            onChange={handleProjectChange}
            label="Select Project"
            >
            {projects?.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                {project.name}
                </MenuItem>
            ))}
            </Select>
        </FormControl>

        {/* Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2, gap: 1 }}>
            <Button variant="outlined" onClick={handleRefresh} disabled={imagesLoading}>
                <Refresh />
            </Button>
            <Button fullWidth variant="contained" onClick={openFilterDialogHandler} startIcon={<FilterList />}>
                Filter
            </Button>
            <Button variant="outlined" onClick={handleResetFilters} disabled={filters.length === 0}>
                <Clear />
            </Button>
        </Box>

        {/* List container with constrained height and scrollable overflow */}
        <Box
            sx={{
            flexGrow: 1,
            border: '1px solid #ccc',
            borderRadius: 2,
            padding: 1,
            overflowY: 'auto',
            minHeight: 0,
            }}
        >
            {imagesLoading ? (
            <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                Loading images...
            </Typography>
            ) : imageError ? (
            <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                Error loading images
            </Typography>
            ) : items.length > 0 ? (
            <List>
                {items.map((item, index) => (
                <ListItem
                    key={item.id}
                    selected={item.id === selectedItemId}
                    onClick={() => handleItemClick(item.id)}
                    sx={{
                    cursor: 'pointer',
                    backgroundColor: item.id === selectedItemId ? 'primary.light' : 'inherit',
                    '&:hover': {
                        backgroundColor: 'primary.highlight',
                        color: 'white',
                    },
                    transition: 'background-color 0.3s ease',
                    borderRadius: '4px',
                    }}
                >
                    {renderItem(item, index + 1 + offset)}
                </ListItem>
                ))}
            </List>
            ) : (
            <Typography sx={{ textAlign: 'center', padding: '16px' }}>
                No items to display
            </Typography>
            )}
        </Box>

        {/* Display total number of items */}
        <Typography sx={{ marginTop: '2px', marginBottom: 0, textAlign: 'right' }}>
            Total: {totalItems}
        </Typography>

        {/* Pagination Component */}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4px' }}>
            <Pagination
            count={Math.ceil(totalItems / limit)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            />
        </Box>

        {/* Filter Dialog */}
        <FilterDialog
            key={selectedItemId}
            projectId={selectedProjectId}
            open={filterDialogOpen}
            onClose={() => setFilterDialogOpen(false)}
            onApplyFilters={handleApplyFilters}
            initialFilters={filters} // Pass current filters as initialFilters
        />
        </Paper>
    );
};

export default AdminExplorerList;
