import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define the base API URL using an environment variable or fallback
const baseAPIURL = window?._env_?.REACT_APP_BASE_API_URL;

// Custom fetchBaseQuery that includes the token from the Redux store
const baseQuery = fetchBaseQuery({
    baseUrl: `${baseAPIURL}/api/annotation`, // Base URL for annotations
    prepareHeaders: (headers, { getState }) => {
        // Access the token from the Redux state
        const token = getState().auth.token;

        // If token is available, add it to the Authorization header
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

// Create the annotation API
export const annotationApi = createApi({
    baseQuery, // Use the baseQuery with token
    reducerPath: "annotationApi",
    tagTypes: ["Annotation"],

    endpoints: (build) => ({

        // Get annotation statistics for a project
        getAnnotationStats: build.query({
            query: (projectId) => ({
                url: `/stats/${projectId}`,
                method: "GET",
            }),
            providesTags: ["Stats"],
        }),

    }),
});

// Export hooks for using the annotation API
export const {
    useGetAnnotationStatsQuery,
} = annotationApi;
